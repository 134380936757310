import React, { FC } from 'react';
import Chip from '@material-ui/core/Chip';
import Tooltip from '@material-ui/core/Tooltip';
import PostCategory from 'interfaces/PostCategory';
import categories from 'utils/categories';
import useStyles from './styles';
import CategoryChipFieldInterface from './interface';

const CategoryChipField: FC<CategoryChipFieldInterface> = ({ record }) => {
  const classes = useStyles();
  const category: PostCategory | undefined = categories.find((cat: PostCategory) => cat?.id === record);
  return (
    <Tooltip arrow title={`${category?.id?.toUpperCase()} - ${category?.name}`}>
      <Chip
        size="small"
        label={`${category?.id?.toUpperCase()}`}
        className={classes.categoryChip}
        style={{ backgroundColor: category?.color }}
      />
    </Tooltip>
  );
};

export default CategoryChipField;
