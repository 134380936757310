import { makeStyles } from '@material-ui/core/styles';
import theme from 'theme';

const useStyles = makeStyles({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  promosHidden: {
    backgroundColor: theme.colors.categories.sdg14,
    color: 'white',
    padding: '20px',
    display: 'flex',
  },
  textInfo: {
    marginLeft: '10px',
  },
});

export default useStyles;
