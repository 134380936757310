import React, { FC, useContext, useEffect } from 'react';
import { Create, TabbedForm, FormTab, useTranslate } from 'react-admin';
import map from 'lodash/map';

import DonationsMainContent from 'components/DonationsMainContent';
import DonationsLocalizationContent from 'components/DonationsLocalizationContent';
import CreateToolbar from 'components/CreateToolbar';
import GbLoader from 'components/GbLoader';

import languageCode from 'utils/languages';
import Donations from 'interfaces/Donations';

import { preparePromoData } from 'utils';

import validateDonations, { validationErrorMessages } from './validateDonations';
import { SaveHandlerContext } from 'providers/saveHandlerProvider';

const PromoCreate: FC = (props) => {
  const translate = useTranslate();
  const { afterSave, setAfterSave } = useContext(SaveHandlerContext);

  // Unmount component will reset the loading global state
  useEffect(() => {
    return () => setAfterSave(false);
  }, []);

  return (
    <Create {...props}>
      <TabbedForm
        className="relative"
        toolbar={
          <CreateToolbar
            resource="donations"
            prepareData={preparePromoData}
            validationErrorMessages={validationErrorMessages}
          />
        }
        validate={(donations: Donations) => validateDonations(donations, translate)}
      >
        <FormTab label="bo.stores.edit.tabs.main" key="main">
          {afterSave && <GbLoader />}
          <DonationsMainContent />
        </FormTab>
        {map(languageCode, (lang, index) => {
          return (
            <FormTab label={lang.name} key={lang.id}>
              {afterSave && <GbLoader />}
              <DonationsLocalizationContent lang={lang} index={index} />
            </FormTab>
          );
        })}
      </TabbedForm>
    </Create>
  );
};
export default PromoCreate;
