import React, { FC } from 'react';
import HightlightFieldProps from './interface';
import StarsIcon from '@material-ui/icons/Stars';
import Box from '@material-ui/core/Box';

const HighlightField: FC<HightlightFieldProps> = ({ record }) => {
  return record?.isHighlight ? (
    <Box textAlign="center" m={0} display="flex" justifyContent="center">
      <StarsIcon />
    </Box>
  ) : null;
};

export default HighlightField;
