import React, { FC } from 'react';

import { List, Datagrid, NumberField, TextField } from 'react-admin';
import Button from '@material-ui/core/Button';
import VisibilityIcon from '@material-ui/icons/Visibility';

interface ListActionsInterface {
  basePath?: string;
}

interface ShowBtnInterface {
  record?: { id: string };
}

const ShowBtn: FC<ShowBtnInterface> = ({ record }) => {
  const manageDonationPath = `${location.origin}/#/donations-subscriptions/${record?.id}`;

  return (
    <Button href={manageDonationPath} color="primary" startIcon={<VisibilityIcon />}>
      SHOW
    </Button>
  );
};

const DonationsTransactionList: FC = (props) => {
  return (
    <List exporter={false} {...props}>
      <Datagrid>
        <TextField source="donationName" label="bo.donations.list.name" />
        <NumberField source="totalSubscriptionsQty" label="bo.donations.list.totalSubscriptionsQty" />
        {/* <NumberField source="pendingSubscriptionsQty" label="bo.donations.list.pendingSubscriptionsQty" /> */}
        <ShowBtn />
      </Datagrid>
    </List>
  );
};

export default DonationsTransactionList;
