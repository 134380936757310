import React, { FC, ReactNode } from 'react';
import { DeleteButton, useTranslate } from 'react-admin';

interface DeleteAdminButtonInterface {
  children?: ReactNode;
  record?: { email: string };
}

const DeleteAdminButton: FC<DeleteAdminButtonInterface> = ({ record, ...props }) => {
  const translate = useTranslate();
  return (
    <DeleteButton
      {...props}
      record={record}
      undoable={false}
      confirmTitle={translate('bo.admin_user.delete.confirm.title', { email: record?.email })}
      confirmContent={translate('bo.admin_user.delete.confirm.content', { email: record?.email })}
    />
  );
};

export default DeleteAdminButton;
