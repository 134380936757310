import React, { FC } from 'react';
import Chip from '@material-ui/core/Chip';
import Tooltip from '@material-ui/core/Tooltip';
import useStyles from './styles';
import TitleChipFieldInterface from './interface';

const TitleChipField: FC<TitleChipFieldInterface> = ({ record }) => {
  const classes = useStyles();
  const title = `${record?.languageCode?.toUpperCase()} - ${record?.title}`;

  // 'active' attribute can be true or undefined, just FALSE results won't be displayed
  // Undefined attribute came from optimistic update
  return record?.active !== false ? (
    <Tooltip arrow title={title}>
      <Chip size="small" label={title} className={classes.titleChip} />
    </Tooltip>
  ) : null;
};
export default TitleChipField;
