import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  csvButton: {
    cursor: 'pointer',
    textDecoration: 'none',
    display: 'flex',
  },
  csvDownload: {
    cursor: 'pointer',
    background: '#3f51b5',
    borderRadius: '4px',
    padding: '10px',
    color: 'white',
    textDecoration: 'none',
    display: 'block',
    textAlign: 'center',
  },
});

export default useStyles;
