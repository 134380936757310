import React, { FC } from 'react';
import { useForm, useFormState } from 'react-final-form';
import { TextInput, BooleanInput } from 'react-admin';
// import RichTextInput from 'ra-input-rich-text';
import RichTextInput from 'components/RichTextInput';
import { Language } from 'interfaces/Language';
import Box from '@material-ui/core/Box';
import Chip from '@material-ui/core/Chip';

import useStyles from './styles';

interface IPromosLocalizationContent {
  lang?: Language;
  index: number;
}
const PromosLocalizationContent: FC<IPromosLocalizationContent> = ({ lang, index }) => {
  const classes = useStyles();

  const form = useForm();
  const formState = useFormState();
  const isActive: boolean = formState?.values?.locales?.[index]?.active;
  const slug: boolean = formState?.values?.locales?.[index]?.slug;
  const languageCode: string = formState?.values?.locales?.[index]?.languageCode;

  // Setting current language
  form.change(`locales[${index}].languageCode`, lang?.id);
  return (
    <>
      <BooleanInput label="bo.stores.edit.fields.active.label" source={`locales[${index}].active`} />

      {slug && (
        <Box mb={2}>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={`${process.env.REACT_APP_BASE_SITE_URL}/${languageCode}/promo/${slug}`}
            className={classes.externalLink}
          >
            <Chip
              size="small"
              label={`${process.env.REACT_APP_BASE_SITE_URL}/${languageCode}/promo/${slug}`}
              className={classes.externalLink}
            />
          </a>
        </Box>
      )}

      <TextInput
        label="bo.promos.edit.fields.name.label"
        source={`locales[${index}].name`}
        fullWidth
        required
        disabled={!isActive}
      />
      <RichTextInput
        name={`locales[${index}].description`}
        label="bo.promos.edit.fields.description.label"
        fullWidth
        required
        disabled={!isActive}
      />

      <TextInput
        label="bo.promos.edit.fields.abstract.label"
        source={`locales[${index}].abstract`}
        fullWidth
        disabled={!isActive}
      />

      <RichTextInput
        name={`locales[${index}].details`}
        label="bo.promos.edit.fields.details.label"
        fullWidth
        disabled={!isActive}
      />

      {/*
      <TextInput
        label="bo.promos.edit.fields.details.label"
        source={`locales[${index}].details`}
        fullWidth
        disabled={!isActive}
      /> */}

      <TextInput
        label="bo.promos.edit.fields.instructions.label"
        source={`locales[${index}].instructions`}
        fullWidth
        disabled={!isActive}
      />
    </>
  );
};

export default PromosLocalizationContent;
