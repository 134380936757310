import React, { FC } from 'react';
import Box from '@material-ui/core/Box';
import {
  AutocompleteArrayInput,
  BooleanInput,
  DateTimeInput,
  NumberInput,
  ReferenceArrayInput,
  SelectArrayInput,
  TextInput,
} from 'react-admin';
import { useForm } from 'react-final-form';
import Category from 'interfaces/Category';
import countries from 'utils/countries';

import ImageInputUploader from 'components/ImageInputUploader';
import useStyles from './styles';

interface IDonationsMainContent {
  editMode?: boolean;
}

const DonationsMainContent: FC<IDonationsMainContent> = () => {
  const classes = useStyles();
  const form = useForm();

  const onImageChange = (id: string, orientation: string): void => {
    form.change(`${orientation}BannerId`, id);
  };
  const showCategoryEN = (choice: Category) => {
    const findEn = choice?.localizations?.find((item) => item.languageCode === 'en');
    return `${findEn?.languageCode?.toUpperCase()} - ${findEn?.name}`;
  };
  return (
    <Box display="flex" flexDirection="column" justifyContent="flex-start" alignContent="flex-start">
      <BooleanInput source="public" label="bo.donations.edit.fields.public.label" initialValue={false} />
      <TextInput label="bo.donations.edit.fields.partnerName.label" source="partnerName" fullWidth required />
      <TextInput label="bo.donations.edit.fields.partnerLink" source="partnerLink" fullWidth />
      <ReferenceArrayInput
        label="bo.donations.edit.fields.categories.label"
        source="categories"
        reference="categories"
        resource="categories"
        perPage={200000}
        parse={(list: string[] = []) => list.map((id) => ({ id }))}
        format={(list: Category[] = []) => list.map(({ id }) => id)}
      >
        <AutocompleteArrayInput optionText={showCategoryEN} fullWidth />
      </ReferenceArrayInput>
      <Box display="flex" flexDirection="row" justifyContent="space-between">
        <Box width="48%" display="flex" flexDirection="column" justifyContent="space-between">
          <DateTimeInput source="expirationDate" label="bo.donations.edit.fields.expirationDate" />
          <NumberInput source="price" label="bo.donations.edit.fields.greenCoinPrice.label" initialValue={0} />
          <SelectArrayInput source="countries" label="bo.stores.edit.fields.countries.label" choices={countries} />
        </Box>
      </Box>
      <Box display="flex" flexDirection="row" justifyContent="space-between">
        <Box width="48%">
          <TextInput key="portraitBannerId" source="portraitBannerId" className={classes.hidden} disabled />
          <ImageInputUploader
            source="portraitBanner"
            label="bo.donations.edit.fields.portraitBanner.label"
            orientation="portrait"
            onChange={onImageChange}
          />
        </Box>
        <Box width="48%">
          <TextInput key="landscapeBannerId" source="landscapeBannerId" className={classes.hidden} disabled />
          <ImageInputUploader
            source="landscapeBanner"
            label="bo.donations.edit.fields.landscapeBanner.label"
            orientation="landscape"
            onChange={onImageChange}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default DonationsMainContent;
