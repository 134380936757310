import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  titleChip: {
    margin: 10,
    marginLeft: 0,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    maxWidth: 300,
    cursor: 'pointer',
  },
});

export default useStyles;
