import React, { FC } from 'react';

import {
  List,
  Datagrid,
  NumberField,
  DateField,
  TextField,
  EditButton,
  sanitizeListRestProps,
  CreateButton,
  TopToolbar,
  ArrayField,
  SingleFieldList,
} from 'react-admin';

import NameChipField from 'components/NameChipField';
import ColourfulBooleanField from 'components/ColourfulBooleanField';
import StoreDeleteButton from 'components/StoreDeleteButton';

interface ListActionsInterface {
  basePath?: string;
}

const ListActions: FC<ListActionsInterface> = ({ basePath, ...rest }) => {
  return (
    <TopToolbar {...sanitizeListRestProps(rest)}>
      <CreateButton basePath={basePath} />
    </TopToolbar>
  );
};

const DonationsList: FC = (props) => {
  return (
    <List exporter={false} actions={<ListActions />} {...props}>
      <Datagrid rowClick="edit">
        <ColourfulBooleanField source="public" label="bo.donations.list.public" />
        <TextField source="partnerName" label="bo.donations.list.partnerName" />
        <ArrayField source="locales" sortable={false} label="bo.donations.list.names">
          <SingleFieldList linkType={false}>
            <NameChipField />
          </SingleFieldList>
        </ArrayField>
        <NumberField source="price" label="bo.donations.list.price" />
        <DateField source="expirationDate" showTime label="bo.donations.list.expirationDate" />
        <EditButton label="bo.donations.list.edit" />
        <StoreDeleteButton label="bo.donations.list.delete" langKey="locales" />
      </Datagrid>
    </List>
  );
};

export default DonationsList;
