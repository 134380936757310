import React, { FC, useContext, useEffect } from 'react';
import { Edit, TabbedForm, FormTab, useTranslate, useEditController, Loading } from 'react-admin';
import map from 'lodash/map';

import EditToolbar from 'components/EditToolbar';
import DonationsMainContent from 'components/DonationsMainContent';
import DonationsLocalizationContent from 'components/DonationsLocalizationContent';
import GbLoader from 'components/GbLoader';

import { preparePromoData } from 'utils';
import languageCode from 'utils/languages';

import Donations from 'interfaces/Donations';

import validateDonations, { validationErrorMessages } from './validateDonations';
import { SaveHandlerContext } from 'providers/saveHandlerProvider';

const PromoEdit: FC = (props) => {
  const translate = useTranslate();
  const { loading } = useEditController(props);
  const { afterSave, setAfterSave } = useContext(SaveHandlerContext);

  // Unmount component will reset the loading global state
  useEffect(() => {
    return () => setAfterSave(false);
  }, []);

  return !loading ? (
    <Edit {...props}>
      <TabbedForm
        className="relative"
        toolbar={<EditToolbar prepareData={preparePromoData} validationErrorMessages={validationErrorMessages} />}
        validate={(promo: Donations) => validateDonations(promo, translate)}
      >
        <FormTab label="bo.promos.edit.tabs.main" key="main">
          {afterSave && <GbLoader />}
          <DonationsMainContent editMode />
        </FormTab>

        {map(languageCode, (lang, index) => {
          return (
            <FormTab label={lang.name} key={lang.id}>
              {afterSave && <GbLoader />}
              <DonationsLocalizationContent lang={lang} index={index} />
            </FormTab>
          );
        })}
      </TabbedForm>
    </Edit>
  ) : (
    <Loading />
  );
};
export default PromoEdit;
