import React, { FC } from 'react';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import EventIcon from '@material-ui/icons/Event';
import PublishDateFieldProps from './interface';
import useStyles from './styles';

const PublishDateField: FC<PublishDateFieldProps> = ({ record }) => {
  const classes = useStyles();
  const publishDate = record?.publishedAt ? new Date(record?.publishedAt) : null;
  const futurePub = publishDate ? publishDate > new Date() : false;
  return publishDate ? (
    <Box display="flex" justifyContent="flex-start" alignItems="center" flexWrap="no-wrap">
      {futurePub ? (
        <Box mr={1} display="flex" justifyContent="flex-start" alignItems="center">
          <EventIcon fontSize="small" color="secondary" />
        </Box>
      ) : null}
      <Typography
        variant="body2"
        color={futurePub ? 'secondary' : 'initial'}
        className={classes.dateValue}
      >{`${publishDate.toLocaleDateString()} ${publishDate.toLocaleTimeString()}`}</Typography>
    </Box>
  ) : null;
};

export default PublishDateField;
