import React, { FC } from 'react';
import { ReferenceField, TextField } from 'react-admin';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TablePagination from '@material-ui/core/TablePagination';
import Paper from '@material-ui/core/Paper';
import parseISO from 'date-fns/parseISO';
import format from 'date-fns/format';
import map from 'lodash/map';

import SubActionButton from './SubActionButton';

interface ISubsStatus {
  id: string;
  subscribedBy: string;
  subscribedOn: string;
  transactionState: string;
}
interface ISubType {
  response: {
    total: number;
    data: {
      results: ISubsStatus[];
    };
  };
  hasAction?: boolean;
  onSelectRowsPerPage: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onSelectPage: (_event: unknown, newPage: number) => void;
  rowsPerPage: number;
  page: number;
}

const SubTabs: FC<ISubType> = ({
  response,
  hasAction = false,
  onSelectPage,
  onSelectRowsPerPage,
  rowsPerPage,
  page,
}) => {
  const formatDates = (date: string): string => format(parseISO(date), 'dd MMMM yyyy HH:mm:ss');
  const subStatusResults = response?.data?.results;

  return (
    <>
      <TableContainer component={Paper}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>User</TableCell>
              <TableCell>Date</TableCell>
              {hasAction && (
                <>
                  <TableCell>Action</TableCell>
                  <TableCell>Action</TableCell>
                </>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {map(subStatusResults, (item: ISubsStatus) => (
              <TableRow key={item.id}>
                <TableCell component="th" scope="row">
                  <ReferenceField
                    basePath="/users"
                    record={item}
                    source="subscribedBy"
                    reference="users"
                    resource="users"
                    link="show"
                  >
                    <TextField source="username" />
                  </ReferenceField>
                </TableCell>
                <TableCell>{formatDates(item.subscribedOn)}</TableCell>
                {hasAction && (
                  <>
                    <TableCell>
                      <SubActionButton record={item} action="CONFIRMED" />
                    </TableCell>
                    <TableCell>
                      <SubActionButton record={item} action="VOID" />
                    </TableCell>
                  </>
                )}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={response?.total ?? 0}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={onSelectPage}
        onChangeRowsPerPage={onSelectRowsPerPage}
      />
    </>
  );
};
export default SubTabs;
