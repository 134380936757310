import React, { FC, useContext, useEffect } from 'react';
import { Edit, TabbedForm, FormTab, useTranslate, useEditController, Loading } from 'react-admin';

import EditToolbar from 'components/EditToolbar';
import StoreMainContent from 'components/StoreMainContent';
import StoreLocalizationContent from 'components/StoreLocalizationContent';
import GbLoader from 'components/GbLoader';

import { prepareStoreData } from 'utils';
import languageCode from 'utils/languages';

import Store from 'interfaces/Store';

import validateStore, { validationErrorMessages } from './validateStore';
import { SaveHandlerContext } from 'providers/saveHandlerProvider';

const StoreEdit: FC = (props) => {
  const translate = useTranslate();
  const { afterSave, setAfterSave } = useContext(SaveHandlerContext);
  const { loading } = useEditController(props);

  // Unmount component will reset the loading global state
  useEffect(() => {
    return () => setAfterSave(false);
  }, []);

  return !loading ? (
    <Edit {...props}>
      <TabbedForm
        className="relative"
        toolbar={<EditToolbar prepareData={prepareStoreData} validationErrorMessages={validationErrorMessages} />}
        validate={(store: Store) => validateStore(store, translate)}
      >
        <FormTab label="bo.stores.edit.tabs.main" key="main">
          {afterSave && <GbLoader />}
          <StoreMainContent editMode />
        </FormTab>

        {languageCode.map((lang, index) => {
          return (
            <FormTab label={lang.name} key={lang.id}>
              {afterSave && <GbLoader />}
              <StoreLocalizationContent lang={lang} index={index} />
            </FormTab>
          );
        })}
      </TabbedForm>
    </Edit>
  ) : (
    <Loading />
  );
};
export default StoreEdit;
