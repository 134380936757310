import { compact, flatten, each, isEmpty } from 'lodash';
import languages, { getLanguageName } from 'utils/languages';
import Category from 'interfaces/Category';
import CategoryLocalization from 'interfaces/CategoryLocalization';

const NAME_MIN_LENGTH = 3;

interface CategoryLocalizationValidationError {
  name?: string;
}

interface CategoryValidationErrors {
  localizations?: CategoryLocalizationValidationError[];
  categories?: string;
  portraitBanner?: string;
  landscapeBanner?: string;
}

const validateLocalizationsQty = (category: Category): string | undefined => {
  if (category?.localizations?.length !== languages.length) {
    return 'bo.posts.edit.fields.localizations.validation';
  }
};

const validatePortaitBanner = (category: Category): string | undefined =>
  isEmpty(category.portraitBanner) ? 'bo.categories.edit.fields.portraitBanner.validation' : undefined;

const validateLandscapeBanner = (category: Category): string | undefined =>
  isEmpty(category.landscapeBanner) ? 'bo.categories.edit.fields.landscapeBanner.validation' : undefined;

const validateName = (localization: CategoryLocalization, translate: Function): string | undefined => {
  if (isEmpty(localization.name))
    return translate('bo.categories.edit.fields.name.validation.required', {
      language: getLanguageName(localization?.languageCode ?? ''),
    });

  if ((localization?.name?.length ?? 0) < NAME_MIN_LENGTH)
    return translate('bo.categories.edit.fields.name.validation.minLength', {
      language: getLanguageName(localization?.languageCode ?? ''),
      minLength: NAME_MIN_LENGTH,
    });
};

const validateLocalization = (localization: CategoryLocalization, translate: Function): any => ({
  name: validateName(localization, translate),
});

const validateCategory = (category: Category, translate: Function): CategoryValidationErrors => ({
  portraitBanner: validatePortaitBanner(category),
  landscapeBanner: validateLandscapeBanner(category),
  localizations: compact(
    category.localizations?.map((localization: CategoryLocalization) => validateLocalization(localization, translate)),
  ),
});

export const validationErrorMessages = (category: Category, translate: Function): string[] => {
  let errors = [validatePortaitBanner(category), validateLandscapeBanner(category), validateLocalizationsQty(category)];

  each(compact(category.localizations), (localization: CategoryLocalization) => {
    errors = errors.concat(Object.values(validateLocalization(localization, translate)));
  });

  return compact(flatten(errors));
};

export default validateCategory;
