import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  helper: {
    position: 'relative',
    listItemType: 'none',
    zIndex: 1,
  },
  sortableItem: {
    cursor: 'grab',
    marginBottom: 5,
  },
});

export default useStyles;
