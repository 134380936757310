import i18next, { i18n } from 'i18next';
import polyglotI18nProvider from 'ra-i18n-polyglot';

import reactAdminEnTranslations from 'ra-language-english';
import enTranslations from 'locales/en/translations.json';

class I18nextProvider {
  private i18Next: i18n;
  private polyglotI18Next: any;

  constructor() {
    this.polyglotI18Next = polyglotI18nProvider(() => reactAdminEnTranslations, 'en');
    this.i18Next = i18next.createInstance();
    this.i18Next.init({
      lng: 'en',
      resources: {
        en: {
          translation: enTranslations,
        },
      },
    });
  }

  public translate = (key: string, options: object): string | undefined => {
    switch (key?.split('.')[0]) {
      case 'ra':
        return this.polyglotI18Next.translate(key, options);
      default:
        return this.i18Next.t(key, options);
    }
  };

  // @TODO: Not implemented
  public changeLocale = (locale: string): Promise<string> => Promise.resolve(locale);

  public getLocale = (): string => this.i18Next.language;
}

export default I18nextProvider;
