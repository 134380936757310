import React, { FC } from 'react';
import { useForm, useFormState, AnyObject } from 'react-final-form';
import { TextInput, BooleanInput } from 'react-admin';
import Box from '@material-ui/core/Box';
import Chip from '@material-ui/core/Chip';
import RichTextInput from 'components/RichTextInput';
import { Language } from 'interfaces/Language';
import useStyles from './styles';

interface IPostMain {
  lang: Language;
  index: number;
}
const PostContent: FC<IPostMain> = ({ lang, index }) => {
  const classes = useStyles();

  const formState: AnyObject = useFormState();
  const form = useForm();

  // Setting current language
  form.change(`postContents[${index}].languageCode`, lang.id);

  const isActive: boolean = formState?.values?.postContents?.[index]?.active;
  const slug: boolean = formState?.values?.postContents?.[index]?.slug;
  const languageCode: string = formState?.values?.postContents?.[index]?.languageCode;

  return (
    <>
      <BooleanInput label="bo.posts.edit.fields.active.label" source={`postContents[${index}].active`} />
      {slug && (
        <Box mb={2}>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={`${process.env.REACT_APP_BASE_SITE_URL}/${languageCode}/article/${slug}`}
            className={classes.externalLink}
          >
            <Chip
              size="small"
              label={`${process.env.REACT_APP_BASE_SITE_URL}/${languageCode}/article/${slug}`}
              className={classes.externalLink}
            />
          </a>
        </Box>
      )}
      <TextInput
        label="bo.posts.edit.fields.title.label"
        source={`postContents[${index}].title`}
        fullWidth
        disabled={!isActive}
        required
      />
      <TextInput
        label="bo.posts.edit.fields.abstract.label"
        source={`postContents[${index}].abstract`}
        multiline
        fullWidth
        disabled={!isActive}
        required
      />
      <RichTextInput
        label="bo.posts.edit.fields.content.label"
        name={`postContents[${index}].content`}
        fullWidth
        disabled={!isActive}
        required
      />
      <TextInput
        label="bo.posts.edit.fields.metaDescription.label"
        source={`postContents[${index}].metaDescription`}
        fullWidth
        disabled={!isActive}
      />
      <TextInput
        label="bo.posts.edit.fields.metaKeywords.label"
        source={`postContents[${index}].metaKeywords`}
        fullWidth
        disabled={!isActive}
      />
    </>
  );
};

export default PostContent;
