import React, { FC, useCallback, useContext } from 'react';
import { useFormState, AnyObject } from 'react-final-form';
import { SaveButton, Toolbar, useNotify, useRedirect, useUpdate, useTranslate } from 'react-admin';
import { SaveHandlerContext } from 'providers/saveHandlerProvider';

interface EditToolbarProps {
  onSave?: Function;
  prepareData?: Function;
  resource?: string;
  validationErrorMessages: Function;
  isSaved?: Function;
}

const EditToolbar: FC<EditToolbarProps> = ({ resource, prepareData, validationErrorMessages, onSave, ...props }) => {
  const formState: AnyObject = useFormState();
  const translate = useTranslate();
  const notify = useNotify();
  const redirectTo = useRedirect();
  const { isUploadingImage, setAfterSave } = useContext(SaveHandlerContext);

  const [update] = useUpdate(
    resource,
    formState.values.id,
    prepareData ? prepareData(formState.values) : formState.values,
    {},
    {
      undoable: false,
      onSuccess: () => {
        notify(`bo.${resource}.feedback.updateCompleted`, 'info');
        redirectTo(`/${resource}`);
      },
      onFailure: (e: { message: string[] }) => {
        setAfterSave(false); // If failed disable the loader
        notify(typeof e.message === 'string' ? e.message : e.message[0], 'warning');
      },
    },
  );

  const notifyValidationErrors = (): void => {
    if (!formState.valid) {
      const errors = validationErrorMessages(formState.values, translate);
      errors.length > 0
        ? notify(errors[0], 'warning')
        : notify(translate(`bo.${resource}.feedback.unknownError`), 'warning');
    }
  };

  const handleOnSave = useCallback(() => {
    setAfterSave(true);
    if (onSave) onSave();
    else update();
  }, [onSave, update]);

  return (
    <Toolbar>
      <SaveButton {...props} onSave={handleOnSave} onClick={notifyValidationErrors} disabled={isUploadingImage} />
    </Toolbar>
  );
};

export default EditToolbar;
