import React, { FC } from 'react';
import { List, Datagrid, BooleanField, TextField, DateField, ReferenceField, Filter, TextInput } from 'react-admin';
import ColourfulBooleanField from 'components/ColourfulBooleanField';

const UserFilter: FC = (props) => (
  <Filter {...props}>
    <TextInput label="Search" source="q" alwaysOn />
  </Filter>
);

const UserList: FC = (props) => {
  return (
    <List exporter={false} filters={<UserFilter />} {...props}>
      <Datagrid rowClick="show" isRowSelectable={() => false}>
        <ColourfulBooleanField source="enabled" sortable={false} />
        <TextField source="email" sortable={false} />
        <ColourfulBooleanField source="enterpriseUser" sortable={false} />
        <TextField source="firstName" sortable={false} />
        <TextField source="lastName" sortable={false} />
        <DateField source="createdAt" showTime sortable={false} />
        <ReferenceField label="invitedBy" source="invitedBy" reference="users" sortable={false}>
          <TextField source="username" />
        </ReferenceField>
      </Datagrid>
    </List>
  );
};

export default UserList;
