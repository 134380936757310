import React, { FC, useContext, useEffect } from 'react';
import { Create, TabbedForm, FormTab, useTranslate } from 'react-admin';

import PromosMainContent from 'components/PromosMainContent';
import PromosLocalizationContent from 'components/PromosLocalizationContent';
import CreateToolbar from 'components/CreateToolbar';

import languageCode from 'utils/languages';
import Promos from 'interfaces/Promos';
import { preparePromoData } from 'utils';
import validatePromos, { validationErrorMessages } from './validatePromos';
import { SaveHandlerContext } from 'providers/saveHandlerProvider';

const PromoCreate: FC = (props) => {
  const translate = useTranslate();
  const { setAfterSave } = useContext(SaveHandlerContext);

  // Unmount component will reset the loading global state
  useEffect(() => {
    return () => setAfterSave(false);
  }, []);

  return (
    <Create {...props}>
      <TabbedForm
        toolbar={
          <CreateToolbar
            resource="promos"
            prepareData={preparePromoData}
            validationErrorMessages={validationErrorMessages}
          />
        }
        validate={(promo: Promos) => validatePromos(promo, translate, 0)}
        redirect="edit"
      >
        <FormTab label="bo.stores.edit.tabs.main" key="main">
          <PromosMainContent />
        </FormTab>
        {languageCode.map((lang, index) => {
          return (
            <FormTab label={lang.name} key={lang.id}>
              <PromosLocalizationContent lang={lang} index={index} />
            </FormTab>
          );
        })}
      </TabbedForm>
    </Create>
  );
};
export default PromoCreate;
