import React, { FC, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useGetList, useMutation, useNotify, useRedirect, Button, useTranslate } from 'react-admin';
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';
import arrayMove from 'array-move';

import Box from '@material-ui/core/Box';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Typography from '@material-ui/core/Typography';
import DragHandleIcon from '@material-ui/icons/DragHandle';
import SaveIcon from '@material-ui/icons/Save';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

import Category from 'interfaces/Category';
import CategoryLocalization from 'interfaces/CategoryLocalization';

import useStyles from './styles';

interface StylesInterface {
  sortableItem: string;
}

interface OnSortEndInterface {
  oldIndex: number;
  newIndex: number;
}

interface SortableContainerInterface {
  items: Category[];
  classes: StylesInterface;
}

interface SortableItemInterface {
  text: string;
  classes: StylesInterface;
}

const DragHandle = SortableHandle(() => (
  <ListItemIcon>
    <DragHandleIcon />
  </ListItemIcon>
));

const SortableItem = SortableElement(({ text, classes }: SortableItemInterface) => {
  return (
    <Box
      border={1}
      borderRadius="borderRadius"
      bgcolor="background.paper"
      borderColor="grey.500"
      className={classes.sortableItem}
    >
      <ListItem ContainerComponent="div">
        <ListItemText primary={text} />

        <ListItemSecondaryAction>
          <DragHandle />
        </ListItemSecondaryAction>
      </ListItem>
    </Box>
  );
});

const CategorySortContainer = SortableContainer(({ items, classes }: SortableContainerInterface) => {
  const locName = (localizations: CategoryLocalization[]): any => {
    return localizations?.length > 0
      ? localizations.find((item: CategoryLocalization) => item.languageCode === 'en')
      : [];
  };
  return (
    <List component="div">
      {items.map(({ id, localizations }, index) => (
        <SortableItem key={id} index={index} text={locName(localizations).name ?? ''} classes={classes} />
      ))}
    </List>
  );
});

const CategorySort: FC = () => {
  const classes = useStyles();
  const notify = useNotify();
  const redirectTo = useRedirect();
  const translate = useTranslate();
  const history = useHistory();
  const [items, setItems] = useState([]);

  const [update] = useMutation({
    type: 'rearrange',
    resource: 'categories',
    payload: items.map((cat: Category, index: number) => ({ id: cat.id, weight: index })),
  });

  const { data, loading } = useGetList('categories', { page: 1, perPage: 100 }, { field: 'weight', order: 'ASC' }, {});

  const onSortEnd = ({ oldIndex, newIndex }: OnSortEndInterface): void => {
    setItems((items) => arrayMove(items, oldIndex, newIndex));
  };

  const updateSorting = async (): Promise<void> => {
    await update();
    notify(`bo.stores.feedback.updateCompleted`, 'info');
    redirectTo(`/categories`);
  };

  useEffect(() => {
    if (!loading && items !== Object.values(data)) setItems(Object.values(data));
  }, [loading]);

  return (
    <>
      <Box display="flex" flexDirection="column" alignItems="flex-start" mt={2}>
        <Button label="bo.stores.sort.back" onClick={() => history.goBack()}>
          <ArrowBackIcon />
        </Button>
        <Typography variant="h4" gutterBottom>
          {translate(`bo.categories.sort.title`)}
        </Typography>
      </Box>
      <CategorySortContainer
        helperClass={classes.helper}
        items={items}
        onSortEnd={onSortEnd}
        lockAxis="y"
        classes={classes}
      />
      <Box display="flex" justifyContent="flex-start">
        <Button label="bo.stores.sort.save" onClick={updateSorting}>
          <SaveIcon />
        </Button>
      </Box>
    </>
  );
};

export default CategorySort;
