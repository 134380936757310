import { makeStyles } from '@material-ui/core/styles';
import theme from 'theme';

const useStyles = makeStyles({
  categoryChip: {
    margin: 10,
    marginLeft: 0,
    color: theme.colors.white,
    maxWidth: 100,
    cursor: 'pointer',
  },
});

export default useStyles;
