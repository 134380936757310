import React, { FC } from 'react';
import { useHistory } from 'react-router-dom';
import {
  ArrayField,
  SingleFieldList,
  List,
  NumberField,
  Datagrid,
  EditButton,
  sanitizeListRestProps,
  TopToolbar,
  CreateButton,
  Button,
} from 'react-admin';
import NameChipField from 'components/NameChipField';
import SortIcon from '@material-ui/icons/Sort';

import CategoryDeleteButton from 'components/CategoryDeleteButton';
interface ListActionsInterface {
  basePath?: string;
}

const ListActions: FC<ListActionsInterface> = ({ basePath, ...rest }) => {
  const history = useHistory();
  return (
    <TopToolbar {...sanitizeListRestProps(rest)}>
      <CreateButton basePath={basePath} />
      <Button label="Sort Categories" onClick={() => history.push('categories/sort-categories')}>
        <SortIcon />
      </Button>
    </TopToolbar>
  );
};

const CategoryList: FC = (props) => {
  return (
    <List exporter={false} {...props} actions={<ListActions />} perPage={100}>
      <Datagrid rowClick="edit">
        <ArrayField source="localizations" label="bo.categories.list.name" sortable={false}>
          <SingleFieldList linkType={false}>
            <NameChipField />
          </SingleFieldList>
        </ArrayField>
        <NumberField source="storesQty" label="bo.categories.list.storesQty" sortable={false} />
        <NumberField source="promotionQty" label="bo.categories.list.promotionQty" sortable={false} />
        <NumberField source="donationQty" label="bo.categories.list.donationQty" sortable={false} />
        <EditButton label="bo.categories.list.edit" />
        <CategoryDeleteButton label="bo.categories.list.delete" />
      </Datagrid>
    </List>
  );
};

export default CategoryList;
