import React, { FC } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import Container from '@material-ui/core/Container';
import useStyles from './styles';

const GbLoader: FC = () => {
  const classes = useStyles();

  return (
    <Container className={classes.loaderWrapper}>
      <CircularProgress />
    </Container>
  );
};
export default GbLoader;
