import React, { FC, useState, useEffect } from 'react';
import { useForm, useFormState } from 'react-final-form';
import Box from '@material-ui/core/Box';
import {
  AutocompleteArrayInput,
  BooleanInput,
  DateTimeInput,
  ReferenceArrayInput,
  SelectArrayInput,
  TextField,
  TextInput,
  useTranslate,
} from 'react-admin';
import ImageInputUploader from 'components/ImageInputUploader';
import categories from 'utils/categories';
import useStyles from './styles';
import PostCategory from 'interfaces/PostCategory';
import Post from 'interfaces/Post';
import PostMainContentInterface from './interface';

const PostMainContent: FC<PostMainContentInterface> = () => {
  const classes = useStyles();
  const form = useForm();
  const formState = useFormState();
  const [relatedsNumber, setRelatedNumber] = useState(form.getFieldState('relatedPostIds')?.length ?? 0);
  const translate = useTranslate();
  let formUnsubscribe: () => void;

  const onImageChange = (id: string, orientation: string): void => {
    form.change(`${orientation}BannerId`, id);
  };

  useEffect(() => {
    if (!formState.values?.published) {
      form.change(`publishedAt`, null);
    }
  }, [formState.values?.published]);

  useEffect(() => {
    formUnsubscribe = form.subscribe((state) => setRelatedNumber(state?.values?.relatedPostIds?.length ?? 0), {
      values: true,
    });
    return formUnsubscribe;
  }, []);

  const findPosts = (choice: Post): string => {
    const findLang = choice.postContents.find((item) => item.active === true);
    return `${findLang?.languageCode?.toUpperCase()} - ${findLang?.title}`;
  };

  return (
    <>
      <Box display="flex" flexDirection="column" justifyContent="flex-start" alignContent="flex-start">
        <TextField label="bo.posts.edit.fields.id" source="id" />
        <BooleanInput source="published" label={translate('bo.posts.edit.fields.published.label')} />
        <DateTimeInput
          source="publishedAt"
          label="bo.posts.edit.fields.publishDate"
          disabled={!formState.values?.published}
        />
        <BooleanInput label="bo.posts.edit.fields.highlight.label" source="isHighlight" />
        <SelectArrayInput
          source="categories"
          choices={categories}
          optionText={(choice: PostCategory) => `${choice?.id.toUpperCase()} - ${choice?.name}`}
          label="bo.posts.edit.fields.categories.label"
          required
        />
        <ReferenceArrayInput
          source="relatedPostIds"
          reference="posts"
          resource="posts"
          label={
            relatedsNumber === 0
              ? 'bo.posts.edit.fields.relateds.alternativelabel'
              : 'bo.posts.edit.fields.relateds.label'
          }
        >
          <AutocompleteArrayInput optionText={findPosts} fullWidth />
        </ReferenceArrayInput>
      </Box>
      <TextInput key="portraitBannerId" source="portraitBannerId" className={classes.hidden} />
      <TextInput key="landscapeBannerId" source="landscapeBannerId" className={classes.hidden} />
      <Box display="flex" flexDirection="row" justifyContent="space-between">
        <Box width="48%">
          <ImageInputUploader
            source="portraitBanner"
            label="bo.posts.edit.fields.portraitBanner.label"
            orientation="portrait"
            onChange={onImageChange}
          />
        </Box>
        <Box width="48%">
          <ImageInputUploader
            source="landscapeBanner"
            label="bo.posts.edit.fields.landscapeBanner.label"
            orientation="landscape"
            onChange={onImageChange}
          />
        </Box>
      </Box>
    </>
  );
};

export default PostMainContent;
