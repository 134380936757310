import React, { FC } from 'react';
import { useForm, useFormState } from 'react-final-form';
import { TextInput, BooleanInput } from 'react-admin';
import Box from '@material-ui/core/Box';
import Chip from '@material-ui/core/Chip';

import RichTextInput from 'components/RichTextInput';
import { Language } from 'interfaces/Language';

import useStyles from './styles';

const StoreLocalizationContent: FC<{ lang: Language; index: number }> = ({ lang, index }) => {
  const classes = useStyles();
  const form = useForm();
  const formState = useFormState();
  const isAffiliateProgramPresent = !!formState?.values?.affiliateProgramId;
  const isActive: boolean = formState?.values?.localizations?.[index]?.active;
  const slug: boolean = formState?.values?.localizations?.[index]?.slug;
  const languageCode: string = formState?.values?.localizations?.[index]?.languageCode;

  // Setting current language
  form.change(`localizations[${index}].languageCode`, lang.id);

  return (
    <>
      <BooleanInput
        label="bo.stores.edit.fields.active.label"
        source={`localizations[${index}].active`}
        disabled={!isAffiliateProgramPresent}
      />
      {slug && (
        <Box mb={2}>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={`${process.env.REACT_APP_BASE_SITE_URL}/${languageCode}/partner/${slug}`}
            className={classes.externalLink}
          >
            <Chip
              size="small"
              label={`${process.env.REACT_APP_BASE_SITE_URL}/${languageCode}/partner/${slug}`}
              className={classes.externalLink}
            />
          </a>
        </Box>
      )}
      <TextInput
        label="bo.stores.edit.fields.name.label"
        source={`localizations[${index}].name`}
        fullWidth
        required
        disabled={!isAffiliateProgramPresent || !isActive}
      />
      <TextInput
        label="bo.stores.edit.fields.tagline.label"
        source={`localizations[${index}].tagline`}
        fullWidth
        disabled={!isAffiliateProgramPresent || !isActive}
      />
      <TextInput
        label="bo.stores.edit.fields.abstract.label"
        source={`localizations[${index}].abstract`}
        fullWidth
        required
        disabled={!isAffiliateProgramPresent || !isActive}
      />
      <RichTextInput
        label="bo.stores.edit.fields.description.label"
        name={`localizations[${index}].description`}
        fullWidth
        required
        disabled={!isAffiliateProgramPresent || !isActive}
      />
      <TextInput
        label="bo.stores.edit.fields.metaDescription.label"
        source={`localizations[${index}].metaDescription`}
        fullWidth
        disabled={!isAffiliateProgramPresent || !isActive}
      />
      <TextInput
        label="bo.stores.edit.fields.metaKeywords.label"
        source={`localizations[${index}].metaKeywords`}
        fullWidth
        disabled={!isAffiliateProgramPresent || !isActive}
      />
    </>
  );
};

export default StoreLocalizationContent;
