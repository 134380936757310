import React, { FC } from 'react';
import { useForm } from 'react-final-form';
import { TextInput } from 'react-admin';
import { Language } from 'interfaces/Language';

const CategoryLocalization: FC<{ lang: Language; index: number }> = ({ lang, index }) => {
  const form = useForm();

  // Setting current language
  form.change(`localizations[${index}].languageCode`, lang.id);

  return (
    <>
      <TextInput
        label="bo.categories.edit.fields.name.label"
        source={`localizations[${index}].name`}
        fullWidth
        required
      />
    </>
  );
};

export default CategoryLocalization;
