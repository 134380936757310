import React, { FC } from 'react';
import { BooleanField } from 'react-admin';
import useStyles from './styles';

interface ColourfulBooleanFieldInterface {
  source: string;
  record?: any;
  label?: string;
  sortable?: boolean;
  addLabel?: boolean;
}

const ColourfulBooleanField: FC<ColourfulBooleanFieldInterface> = ({
  addLabel,
  record,
  source,
  sortable,
  ...props
}) => {
  const classes = useStyles();
  return (
    <BooleanField
      addLabel={addLabel}
      record={record}
      source={source}
      sortable={sortable}
      className={record?.[source] ? classes.true : classes.false}
      {...props}
    />
  );
};

export default ColourfulBooleanField;
