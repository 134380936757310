import React, { FC, useCallback, useContext } from 'react';
import { useFormState, AnyObject } from 'react-final-form';
import { SaveButton, Toolbar, useCreate, useNotify, useRedirect, useTranslate } from 'react-admin';
import { SaveHandlerContext } from 'providers/saveHandlerProvider';

interface CreateToolbarProps {
  onSave?: Function;
  prepareData?: Function;
  resource?: string;
  validationErrorMessages: Function;
  isSaved?: Function;
  basePath?: string;
}

const CreateToolbar: FC<CreateToolbarProps> = ({
  resource,
  prepareData,
  validationErrorMessages,
  onSave,
  basePath,
  ...props
}) => {
  const formState: AnyObject = useFormState();
  const notify = useNotify();
  const redirectTo = useRedirect();
  const translate = useTranslate();
  const [create] = useCreate(resource);
  const { isUploadingImage, setAfterSave } = useContext(SaveHandlerContext);

  const notifyValidationErrors = (): void => {
    if (!formState.valid) {
      const errors = validationErrorMessages(formState.values, translate);
      errors.length > 0
        ? notify(errors[0], 'warning')
        : notify(translate(`bo.${resource}.feedback.unknownError`), 'warning');
    }
  };

  const save = useCallback(
    (values, redirect) => {
      create(
        {
          payload: {
            data: prepareData ? prepareData(values) : values,
          },
        },
        {
          undoable: false,
          onSuccess: ({ data: newRecord }: any) => {
            notify(`bo.${resource}.feedback.updateCompleted`, 'info', null);
            if (resource === 'promos' && redirect === 'edit') {
              redirectTo(`/${resource}/${newRecord.id}`);
            } else {
              redirectTo(`/${resource}`);
            }
          },
          onFailure: (e: { message: string[] }) => {
            setAfterSave(false); // If failed disable the loader
            notify(typeof e.message === 'string' ? e.message : e.message[0], 'warning');
          },
        },
      );
    },
    [create, notify, redirectTo, basePath],
  );

  const handleOnSave = useCallback(
    (_val, redirect) => {
      setAfterSave(true);
      if (onSave) onSave();
      else save(formState.values, redirect);
    },
    [onSave, formState.values],
  );

  return (
    <Toolbar>
      <SaveButton {...props} onSave={handleOnSave} onClick={notifyValidationErrors} disabled={isUploadingImage} />
    </Toolbar>
  );
};

export default CreateToolbar;
