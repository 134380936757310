import React, { FC } from 'react';
import {
  Show,
  Labeled,
  SimpleShowLayout,
  TextField,
  DateField,
  ReferenceField,
  useShowController,
  useQuery,
} from 'react-admin';
import SectionTitle from 'components/SectionTitle';
import ColourfulBooleanField from 'components/ColourfulBooleanField';
import Box from '@material-ui/core/Box';

/*
import User from 'interfaces/User';
const UserShowActions: FC<{ basePath?: string; data?: User }> = ({ basePath, data }) => (
  <TopToolbar>
    <EditButton basePath={basePath} record={data} />
  </TopToolbar>
); */

const UserShow: FC = (props) => {
  const { record } = useShowController(props);

  const { data: wallet, loading: userDataLoading } = useQuery({
    type: 'get-user-wallet',
    resource: 'accounts',
    payload: { id: record?.id },
  });

  const availableBalanceGCN =
    wallet && wallet.find((w: { currency: { code: string } }) => w.currency.code === 'GCN')?.availableBalance;
  const availableBalanceGKM =
    wallet && wallet.find((w: { currency: { code: string } }) => w.currency.code === 'GKM')?.availableBalance;

  const netBalanceGCN =
    wallet && wallet.find((w: { currency: { code: string } }) => w.currency.code === 'GCN')?.netBalance;
  const netBalanceGKM =
    wallet && wallet.find((w: { currency: { code: string } }) => w.currency.code === 'GKM')?.netBalance;

  const authorizedAmount =
    wallet && wallet.find((w: { currency: { code: string } }) => w.currency.code === 'GCN').authorizedAmount;

  return (
    <Show title={`${record?.firstName} ${record?.lastName}`} {...props}>
      <SimpleShowLayout>
        <SectionTitle label={`${record?.firstName} ${record?.lastName}`} />
        <DateField source="createdAt" showTime />
        <TextField source="email" />
        {record?.invitedBy && (
          <ReferenceField source="invitedBy" reference="users" link="show">
            <TextField source="email" />
          </ReferenceField>
        )}
        <ColourfulBooleanField source="enterpriseUser" addLabel={true} />
        <ColourfulBooleanField source="enabled" addLabel={true} />

        <TextField source="address" />
        <TextField source="ageGroup" />
        <TextField source="phoneNumber" />

        <Labeled label="Gocce available balance (net balance) (authorized amount) ">
          <Box>
            {availableBalanceGCN || '-'} ({netBalanceGCN || '-'}) ({authorizedAmount || '-'})
          </Box>
        </Labeled>
        <Labeled label="Green Km available balance (net balance)">
          <Box>
            {availableBalanceGKM || '-'} ({netBalanceGKM || '-'})
          </Box>
        </Labeled>
      </SimpleShowLayout>
    </Show>
  );
};

export default UserShow;
