const theme = {
  colors: {
    white: '#fff',
    categories: {
      sdg1: '#e7000e',
      sdg2: '#d8a700',
      sdg3: '#009735',
      sdg4: '#c90822',
      sdg5: '#e93600',
      sdg6: '#00a5db',
      sdg7: '#fabe00',
      sdg8: '#98052f',
      sdg9: '#ef6a00',
      sdg10: '#df007a',
      sdg11: '#f6a200',
      sdg12: '#d49200',
      sdg13: '#3f7a30',
      sdg14: '#0074bd',
      sdg15: '#1aa931',
      sdg16: '#004a89',
      sdg17: '#002e69',
      pending: '#f6a200',
      confirmed: '#009735',
      void: '#e7000e',
    },
  },
};

export default theme;
