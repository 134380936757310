import { makeStyles } from '@material-ui/core/styles';
import theme from 'theme';

const useStyles = makeStyles({
  btnApprove: {
    '& span': {
      padding: 0,
    },
  },
  btnReject: {
    color: theme.colors.categories.sdg5,
  },
});

export default useStyles;
