import React, { FC } from 'react';
import { DeleteButton, useTranslate } from 'react-admin';
import Post from 'interfaces/Post';

interface IPostDeleteButton {
  record?: Post;
  label?: string;
}

const PostDeleteButton: FC<IPostDeleteButton> = ({ record, label, ...props }) => {
  const t = useTranslate();
  const resourceName = record?.postContents.find((item: any) => item.active === true);

  return (
    <DeleteButton
      label={label}
      confirmTitle={`${t('bo.posts.delete')}  ${resourceName?.title}`}
      undoable={false}
      record={record}
      {...props}
    />
  );
};
export default PostDeleteButton;
