import React, { FC, useContext, useEffect } from 'react';
import { Create, TabbedForm, FormTab, useTranslate } from 'react-admin';

import CreateToolbar from 'components/CreateToolbar';
import CategoryMainContent from 'components/CategoryMainContent';
import CategoryLocalization from 'components/CategoryLocalization';
import GbLoader from 'components/GbLoader';

import languageCode from 'utils/languages';
import Category from 'interfaces/Category';
import validateCategory, { validationErrorMessages } from './validateCategory';
import { SaveHandlerContext } from 'providers/saveHandlerProvider';

const CategoryCreate: FC = (props) => {
  const translate = useTranslate();
  const { afterSave, setAfterSave } = useContext(SaveHandlerContext);

  // Unmount component will reset the loading global state
  useEffect(() => {
    return () => setAfterSave(false);
  }, []);

  return (
    <Create {...props}>
      <TabbedForm
        className="relative"
        toolbar={<CreateToolbar validationErrorMessages={validationErrorMessages} />}
        validate={(category: Category) => validateCategory(category, translate)}
      >
        <FormTab label="bo.categories.edit.tabs.main" key="main">
          {afterSave && <GbLoader />}
          <CategoryMainContent />
        </FormTab>
        {languageCode.map((lang, index) => {
          return (
            <FormTab label={lang.name} key={lang.id}>
              {afterSave && <GbLoader />}
              <CategoryLocalization lang={lang} index={index} />
            </FormTab>
          );
        })}
      </TabbedForm>
    </Create>
  );
};
export default CategoryCreate;
