import React, { FC } from 'react';
import { useTranslate } from 'react-admin';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TablePagination from '@material-ui/core/TablePagination';
import Paper from '@material-ui/core/Paper';

import useStyles from '../PromoCodesList/styles';
import map from 'lodash/map';
import { usePromoCodes } from 'providers/promoCodesProvider';
interface IPromoCodesList {
  codes?: {};
}
const PromoCodesList: FC<IPromoCodesList> = () => {
  const { currentCSVCodes, page, promoId, getCSVcodes, setPage, limit } = usePromoCodes();
  const classes = useStyles();
  const t = useTranslate();
  const handleChangePage = (_event: unknown, newPage: number): void => {
    setPage(newPage);
    getCSVcodes({ promoId, page: newPage });
  };

  return (
    <TableContainer component={Paper} className={`${classes.codeList} code-list`}>
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Code</TableCell>
            <TableCell>Availability</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {map(currentCSVCodes?.data, (item: { value: string; totalSubscriptions: number }) => (
            <TableRow key={item.value}>
              <TableCell>{item.value}</TableCell>
              <TableCell>
                {item.totalSubscriptions
                  ? t('bo.promos.edit.fields.promoCode.used')
                  : t('bo.promos.edit.fields.promoCode.available')}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <TablePagination
        rowsPerPageOptions={[]}
        component="div"
        count={currentCSVCodes?.total}
        rowsPerPage={limit}
        page={page}
        onChangePage={handleChangePage}
      />
    </TableContainer>
  );
};
export default PromoCodesList;
