import React, { FC, useContext, useEffect } from 'react';
import { Create, TabbedForm, FormTab, useTranslate } from 'react-admin';

import StoreMainContent from 'components/StoreMainContent';
import StoreLocalizationContent from 'components/StoreLocalizationContent';
import CreateToolbar from 'components/CreateToolbar';
import GbLoader from 'components/GbLoader';

import languageCode from 'utils/languages';
import Store from 'interfaces/Store';

import { prepareStoreData } from 'utils';

import validateStore, { validationErrorMessages } from './validateStore';
import { SaveHandlerContext } from 'providers/saveHandlerProvider';

const StoreCreate: FC = (props) => {
  const translate = useTranslate();
  const { afterSave, setAfterSave } = useContext(SaveHandlerContext);

  // Unmount component will reset the loading global state
  useEffect(() => {
    return () => setAfterSave(false);
  }, []);

  return (
    <Create {...props}>
      <TabbedForm
        className="relative"
        toolbar={
          <CreateToolbar
            resource="store"
            prepareData={prepareStoreData}
            validationErrorMessages={validationErrorMessages}
          />
        }
        validate={(store: Store) => validateStore(store, translate)}
      >
        <FormTab label="bo.stores.edit.tabs.main" key="main">
          {afterSave && <GbLoader />}
          <StoreMainContent />
        </FormTab>
        {languageCode.map((lang, index) => {
          return (
            <FormTab label={lang.name} key={lang.id}>
              {afterSave && <GbLoader />}
              <StoreLocalizationContent lang={lang} index={index} />
            </FormTab>
          );
        })}
      </TabbedForm>
    </Create>
  );
};
export default StoreCreate;
