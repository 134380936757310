import React, { FC } from 'react';
import { useForm, useFormState } from 'react-final-form';
import { TextInput, BooleanInput } from 'react-admin';
import Box from '@material-ui/core/Box';
import Chip from '@material-ui/core/Chip';

import RichTextInput from 'components/RichTextInput';
import { Language } from 'interfaces/Language';
import useStyles from './styles';

const DonationsLocalizationContent: FC<{ lang: Language; index: number }> = ({ lang, index }) => {
  const classes = useStyles();

  const form = useForm();
  const formState = useFormState();
  const isActive: boolean = formState?.values?.locales?.[index]?.active;
  const slug: boolean = formState?.values?.locales?.[index]?.slug;
  const languageCode: string = formState?.values?.locales?.[index]?.languageCode;

  // Setting current language
  form.change(`locales[${index}].languageCode`, lang.id);

  return (
    <>
      <BooleanInput label="bo.stores.edit.fields.active.label" source={`locales[${index}].active`} />

      {slug && (
        <Box mb={2}>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={`${process.env.REACT_APP_BASE_SITE_URL}/${languageCode}/donation/${slug}`}
            className={classes.externalLink}
          >
            <Chip
              size="small"
              label={`${process.env.REACT_APP_BASE_SITE_URL}/${languageCode}/donation/${slug}`}
              className={classes.externalLink}
            />
          </a>
        </Box>
      )}

      <TextInput
        label="bo.donations.edit.fields.name.label"
        source={`locales[${index}].name`}
        fullWidth
        required
        disabled={!isActive}
      />
      <RichTextInput
        label="bo.donations.edit.fields.description.label"
        name={`locales[${index}].description`}
        fullWidth
        required
        disabled={!isActive}
      />
      <TextInput
        label="bo.donations.edit.fields.abstract.label"
        source={`locales[${index}].abstract`}
        fullWidth
        disabled={!isActive}
      />

      <TextInput
        label="bo.donations.edit.fields.thankYouNote.label"
        source={`locales[${index}].thankYouNote`}
        required
        fullWidth
        disabled={!isActive}
      />
    </>
  );
};

export default DonationsLocalizationContent;
