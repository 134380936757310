import React, { FC } from 'react';
import { List, Datagrid, TextField, DateField } from 'react-admin';
import DeleteAdminButton from 'components/DeleteAdminButton';
import ColourfulBooleanField from 'components/ColourfulBooleanField';

const AdminUserList: FC = (props) => {
  return (
    <List exporter={false} {...props}>
      <Datagrid isRowSelectable={() => false}>
        <ColourfulBooleanField source="enabled" sortable={false} />
        <TextField source="email" sortable={false} />
        <TextField source="firstName" sortable={false} />
        <TextField source="lastName" sortable={false} />
        <DateField source="createdAt" showTime sortable={false} />
        <DeleteAdminButton />
      </Datagrid>
    </List>
  );
};

export default AdminUserList;
