import React, { FC } from 'react';
import Chip from '@material-ui/core/Chip';
import statusColor from './status';
import IStatusChip from './interface';

const StatusChip: FC<IStatusChip> = ({ record }) => {
  const status = statusColor.find((item) => item?.id === record?.commissionStatus);

  return <Chip size="small" label={status?.name} style={{ backgroundColor: status?.color }} />;
};

export default StatusChip;
