import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  false: {
    '& svg': {
      fill: 'red',
    },
  },
  true: {
    '& svg': {
      fill: 'green',
    },
  },
});

export default useStyles;
