import { omit, compact, cloneDeep } from 'lodash';
import HttpClient from '../providers/httpClient';
import PostContent from 'interfaces/PostContent';
import Post from 'interfaces/Post';
import Store from 'interfaces/Store';
import StoreLocalization from 'interfaces/StoreLocalization';
import Promos from 'interfaces/Promos';
import PromosLocalization from 'interfaces/PromosLocalization';
import Donations from 'interfaces/Donations';

export const preparePostData = (values: Post): object => {
  const cleanValues = cloneDeep(values);
  // Remove useless fields
  cleanValues.postContents = compact(
    cleanValues?.postContents
      .filter((postContent: PostContent) => postContent?.active)
      .map((postContent: PostContent) => omit(postContent, ['active', 'slug']))
      .map((postContent: PostContent) => {
        postContent = { metaDescription: '', metaKeywords: '', ...postContent };
        return postContent;
      }),
  );
  return omit(cleanValues, ['createdAt', 'updatedAt', 'portraitBanner', 'landscapeBanner', 'published']);
};

export const prepareStoreData = (values: Store): object => {
  const cleanValues = cloneDeep(values);
  // Remove useless fields
  cleanValues.localizations = compact(
    cleanValues?.localizations
      .filter((localization: StoreLocalization) => localization?.active)
      .map((localization: StoreLocalization) => omit(localization, ['active', 'slug']))
      .map((localization: StoreLocalization) => {
        localization = { metaDescription: '', metaKeywords: '', ...localization };
        return localization;
      }),
  );
  return omit(cleanValues, ['landscapeBanner']);
};

export const preparePromoData = (values: Promos): object => {
  const cleanValues = cloneDeep(values);
  // Remove useless fields
  cleanValues.locales = compact(
    cleanValues?.locales
      .filter((locale: PromosLocalization) => locale?.active)
      .map((locale: PromosLocalization) => omit(locale, ['active', 'slug']))
      .map((locale: PromosLocalization) => {
        locale = { ...locale };
        return locale;
      }),
  );
  return omit(cleanValues, ['landscapeBanner', 'portraitBanner']);
};

export const prepareDonationsData = (values: Donations): object => {
  const cleanValues = cloneDeep(values);
  // Remove useless fields
  cleanValues.locales = compact(
    cleanValues?.locales
      .filter((locale: PromosLocalization) => locale?.active)
      .map((locale: PromosLocalization) => omit(locale, ['active', 'slug']))
      .map((locale: PromosLocalization) => {
        locale = { ...locale };
        return locale;
      }),
  );
  return omit(cleanValues, ['landscapeBanner', 'portraitBanner']);
};

export const createImageEntity = async (requestBody: object): Promise<any> => {
  try {
    const response: object & { json?: object } = await HttpClient(`${process.env.REACT_APP_ASSET_STORE}/api/assets`, {
      method: 'POST',
      body: JSON.stringify(requestBody),
    });
    return response.json;
  } catch (error) {
    console.log(error);
  }
};

export const uploadToS3 = async (uploadUrl: string, file: File): Promise<void> => {
  try {
    await fetch(uploadUrl, {
      method: 'PUT',
      body: file,
    });
  } catch (error) {
    console.log(error);
  }
};
