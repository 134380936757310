import React, { FC } from 'react';
import { ArrayField, SingleFieldList, List, Datagrid, EditButton } from 'react-admin';
import CategoryChipField from 'components/CategoryChipField';
import TitleChipField from 'components/TitleChipField';
import HighlightField from 'components/HighlightField';
import PublishDateField from 'components/PublishDateField';
import PostDeleteButton from 'components/PostDeleteButton';

const PostList: FC = (props) => {
  return (
    <List sort={{ field: 'publishedAt', order: 'DESC' }} exporter={false} {...props}>
      <Datagrid rowClick="edit">
        <PublishDateField source="publishedAt" label="bo.posts.list.publishedAt" />
        <HighlightField source="isHighlight" label="bo.posts.list.highlight" addLabel />
        <ArrayField source="categories" label="bo.posts.list.categories" sortable={false}>
          <SingleFieldList linkType={false}>
            <CategoryChipField />
          </SingleFieldList>
        </ArrayField>
        <ArrayField source="postContents" label="bo.posts.list.title" sortable={false}>
          <SingleFieldList linkType={false}>
            <TitleChipField />
          </SingleFieldList>
        </ArrayField>
        <EditButton label="bo.posts.list.edit" />
        <PostDeleteButton />
      </Datagrid>
    </List>
  );
};

export default PostList;
