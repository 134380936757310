/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React, { FC, useContext, useEffect } from 'react';
import { Create, TabbedForm, FormTab, useTranslate } from 'react-admin';

import CreatePostToolbar from 'components/CreatePostToolbar';
import PostContent from 'components/PostContent';
import PostMainContent from 'components/PostMainContent';
import GbLoader from 'components/GbLoader';

import languageCode from 'utils/languages';
import validatePost, { validationErrorMessages } from './validatePost';
import Post from 'interfaces/Post';
import { preparePostData } from 'utils';
import { SaveHandlerContext } from 'providers/saveHandlerProvider';

const PostCreate: FC = (props) => {
  const translate = useTranslate();
  const { afterSave, setAfterSave } = useContext(SaveHandlerContext);

  // Unmount component will reset the loading global state
  useEffect(() => {
    return () => setAfterSave(false);
  }, []);

  return (
    <Create {...props} position="relative">
      <TabbedForm
        className="relative"
        toolbar={<CreatePostToolbar prepareData={preparePostData} validationErrorMessages={validationErrorMessages} />}
        validate={(post: Post) => validatePost(post, translate)}
      >
        <FormTab label="bo.posts.edit.tabs.main" key="main">
          {afterSave && <GbLoader />}
          <PostMainContent />
        </FormTab>
        {languageCode.map((lang, index) => {
          return (
            <FormTab label={lang.name} key={lang.id}>
              {afterSave && <GbLoader />}
              <PostContent lang={lang} index={index} />
            </FormTab>
          );
        })}
      </TabbedForm>
    </Create>
  );
};
export default PostCreate;
