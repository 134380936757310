const countries = [
  { id: 'AT', name: 'austria' },
  { id: 'CA', name: 'canada' },
  { id: 'DK', name: 'denmark' },
  { id: 'FR', name: 'france' },
  { id: 'FI', name: 'finland' },
  { id: 'DE', name: 'germany' },
  { id: 'IT', name: 'italy' },
  { id: 'NL', name: 'netherlands' },
  { id: 'NO', name: 'norway' },
  { id: 'ES', name: 'spain' },
  { id: 'SE', name: 'sweden' },
  { id: 'CH', name: 'switzerland' },
  { id: 'GB', name: 'united-kingdom' },
  { id: 'US', name: 'united-states-of-america' },
].map((country) => ({ ...country, name: `bo.countries.${country.name}` }));

export default countries;
