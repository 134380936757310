import React, { FC } from 'react';

import {
  List,
  Datagrid,
  NumberField,
  DateField,
  TextField,
  EditButton,
  sanitizeListRestProps,
  CreateButton,
  TopToolbar,
} from 'react-admin';

import ColourfulBooleanField from 'components/ColourfulBooleanField';
import StoreDeleteButton from 'components/StoreDeleteButton';

interface ListActionsInterface {
  basePath?: string;
}

const ListActions: FC<ListActionsInterface> = ({ basePath, ...rest }) => {
  return (
    <TopToolbar {...sanitizeListRestProps(rest)}>
      <CreateButton basePath={basePath} />
    </TopToolbar>
  );
};

const PromoList: FC = (props) => {
  return (
    <List exporter={false} actions={<ListActions />} {...props}>
      <Datagrid rowClick="edit">
        <ColourfulBooleanField source="public" label="bo.promos.list.public" />
        <TextField source="partnerName" label="bo.promos.list.partnerName" />
        <NumberField source="price" label="bo.promos.list.price" />
        <DateField source="expirationDate" showTime label="bo.promos.list.expirationDate" />
        <EditButton label="bo.promos.list.edit" />
        <StoreDeleteButton langKey="locales" label="ra.action.delete" />
      </Datagrid>
    </List>
  );
};

export default PromoList;
