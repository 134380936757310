import { compact, flatten, each, isEmpty, omit, map, isNumber } from 'lodash';
import { getLanguageName } from 'utils/languages';
import DonationsLocalization from 'interfaces/DonationsLocalization';
import Donations from 'interfaces/Donations';

const NAME_MIN_LENGTH = 3;
const NAME_MAX_LENGTH = 255;
const ABSTRACT_MIN_LENGTH = 3;
const ABSTRACT_MAX_LENGTH = 255;
const DESCRIPTION_MIN_LENGTH = 3;

interface DonationsLocalizationValidationError {
  name?: string;
  abstract?: string;
  description?: string;
  details?: string;
  instructions?: string;
  thankYouNote?: string;
}

interface DonationsValidationErrors {
  locales?: DonationsLocalizationValidationError[];
  localizationsQty?: string;
  landscapeBanner?: string;
  portraitBanner?: string;
  price?: string;
  partnerName?: string;
  categories?: string;
}

const validateLocalizationsQty = (donations: Donations): string | undefined => {
  if (
    (donations?.locales?.length ?? 0) === 0 ||
    (map(donations?.locales, (content) => omit(content, ['languageCode']))
      .filter((content) => content.active)
      .filter((content) => Object.keys(content).length > 0).length ?? 0) === 0
  ) {
    return 'bo.stores.edit.fields.localizations.validation';
  }
};
const validateCategories = (post: Donations): string | undefined =>
  isEmpty(post.categories) ? 'bo.donations.edit.fields.categories.validation.required' : undefined;

const validateLandscapeBanner = (donations: Donations): string | undefined =>
  isEmpty(donations.landscapeBanner) ? 'bo.donations.edit.fields.landscapeBanner.validation' : undefined;

const validatePortraitBanner = (donations: Donations): string | undefined =>
  isEmpty(donations.portraitBanner) ? 'bo.donations.edit.fields.portraitBanner.validation' : undefined;

const validateGreenCoin = (donations: Donations): string | undefined =>
  !isNumber(donations.price) || donations.price < 0 ? 'bo.donations.edit.fields.greenCoinPrice.validation' : undefined;

const validatePartnerName = (donations: Donations): string | undefined =>
  isEmpty(donations.partnerName) ? 'bo.donations.edit.fields.partnerName.required' : undefined;

const validateThankYou = (localization: DonationsLocalization, translate: Function): string | undefined => {
  if (isEmpty(localization.thankYouNote))
    return translate('bo.donations.edit.fields.thankYouNote.required', {
      language: getLanguageName(localization?.languageCode ?? ''),
    });
};

const validateName = (localization: DonationsLocalization, translate: Function): string | undefined => {
  if (isEmpty(localization.name))
    return translate('bo.stores.edit.fields.name.validation.required', {
      language: getLanguageName(localization?.languageCode ?? ''),
    });

  if ((localization?.name?.length ?? 0) < NAME_MIN_LENGTH)
    return translate('bo.stores.edit.fields.name.validation.minLength', {
      language: getLanguageName(localization?.languageCode ?? ''),
      minLength: NAME_MIN_LENGTH,
    });

  if ((localization?.name?.length ?? 0) > NAME_MAX_LENGTH)
    return translate('bo.stores.edit.fields.name.validation.maxLength', {
      language: getLanguageName(localization?.languageCode ?? ''),
      maxLength: NAME_MAX_LENGTH,
    });
};

const validateDescription = (localization: DonationsLocalization, translate: Function): string | undefined => {
  if (isEmpty(localization.description))
    return translate('bo.stores.edit.fields.description.validation.required', {
      language: getLanguageName(localization?.languageCode ?? ''),
    });

  if ((localization?.description?.length ?? 0) < DESCRIPTION_MIN_LENGTH)
    return translate('bo.stores.edit.fields.description.validation.minLength', {
      language: getLanguageName(localization?.languageCode ?? ''),
      minLength: DESCRIPTION_MIN_LENGTH,
    });
};

const validateLocalization = (
  localization: DonationsLocalization,
  translate: Function,
): DonationsLocalizationValidationError => ({
  name: localization.active ? validateName(localization, translate) : undefined,
  description: localization.active ? validateDescription(localization, translate) : undefined,
  thankYouNote: localization.active ? validateThankYou(localization, translate) : undefined,
});

const validateDonations = (donations: Donations, translate: Function): DonationsValidationErrors => ({
  landscapeBanner: validateLandscapeBanner(donations),
  portraitBanner: validatePortraitBanner(donations),
  price: validateGreenCoin(donations),
  partnerName: validatePartnerName(donations),
  locales: compact(
    donations.locales?.map((localization: DonationsLocalization) => validateLocalization(localization, translate)),
  ),
  localizationsQty: validateLocalizationsQty(donations),
  categories: validateCategories(donations),
});
export const validationErrorMessages = (donations: Donations, translate: Function): string[] => {
  let errors = [
    validateLandscapeBanner(donations),
    validateLocalizationsQty(donations),
    validateGreenCoin(donations),
    validatePartnerName(donations),
    validateCategories(donations),
  ];

  each(compact(donations.locales), (localization: DonationsLocalization) => {
    errors = errors.concat(Object.values(validateLocalization(localization, translate)));
  });

  return compact(flatten(errors));
};

export default validateDonations;
