import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import Box from '@material-ui/core/Box';
import {
  TextInput,
  ReferenceInput,
  AutocompleteInput,
  SelectArrayInput,
  BooleanInput,
  NumberInput,
  ReferenceArrayInput,
  AutocompleteArrayInput,
  useTranslate,
} from 'react-admin';
import { useForm, useFormState, AnyObject } from 'react-final-form';

import ImageInputUploader from 'components/ImageInputUploader';
import countries from 'utils/countries';
import Category from 'interfaces/Category';
import AffiliateProgram from 'interfaces/AffiliateProgram';

import useStyles from './styles';
import partnerLogoPlaceholder from './assets/images/partner-logo-placeholder.png';

interface RootState {
  admin: {
    resources: {
      'affiliate-programs': {
        data: AffiliateProgram[];
      };
    };
  };
}

interface StoreMainContentInterface {
  editMode?: boolean;
}

const StoreMainContent: FC<StoreMainContentInterface> = ({ editMode = false }) => {
  const classes = useStyles();
  const t = useTranslate();
  const formState: AnyObject = useFormState();
  const form = useForm();
  const affiliatePrograms = useSelector((state: RootState) => state.admin.resources['affiliate-programs'].data);
  const onImageChange = (id: string, orientation: string): void => {
    form.change(`${orientation}BannerId`, id);
  };

  const affiliateProgramId: number = formState?.values?.affiliateProgramId;
  const affiliateProgramChosen = !!affiliateProgramId;
  const affiliateProgram: AffiliateProgram = affiliatePrograms[affiliateProgramId];
  // const affiliateProgramPrimaryRegion: string = affiliateProgram?.primaryRegion?.countryCode;
  const isGreenDelivery: boolean = formState?.values?.isGreenDelivery;

  const showCategoryEN = (choice: Category): string => {
    const findEn = choice?.localizations?.find((item) => item.languageCode === 'en');
    return `${findEn?.languageCode?.toUpperCase()} - ${findEn?.name}`;
  };

  // useEffect(() => {
  // if (affiliateProgramPrimaryRegion) form.change(`countries`, [affiliateProgramPrimaryRegion]);
  // }, [affiliateProgramPrimaryRegion]);

  return (
    <Box display="flex" flexDirection="column" justifyContent="flex-start" alignContent="flex-start">
      <Box display="flex" justifyContent="flex-start" alignContent="flex-start">
        <img src={affiliateProgram?.logoUrl ?? partnerLogoPlaceholder} />
      </Box>
      <ReferenceInput
        label="bo.stores.edit.fields.affiliateProgram.label"
        source="affiliateProgramId"
        reference="affiliate-programs"
        required
        perPage={200000}
        disabled={editMode}
        className={editMode ? classes.readOnly : null}
      >
        <AutocompleteInput optionText="name" fullWidth disabled={editMode} />
      </ReferenceInput>

      <ReferenceArrayInput
        label="bo.stores.edit.fields.categories.label"
        source="categories"
        reference="categories"
        resource="categories"
        perPage={200000}
        disabled={!affiliateProgramChosen}
        parse={(list: string[] = []) => list.map((id) => ({ id }))}
        format={(list: Category[] = []) => list.map(({ id }) => id)}
      >
        <AutocompleteArrayInput optionText={showCategoryEN} fullWidth />
      </ReferenceArrayInput>
      <Box display="flex">
        <BooleanInput
          source="public"
          label="bo.stores.edit.fields.public.label"
          initialValue={false}
          disabled={!affiliateProgramChosen}
        />
        <BooleanInput
          source="isHighlight"
          label="bo.stores.edit.fields.highlight.label"
          initialValue={false}
          disabled={!affiliateProgramChosen}
        />
        <BooleanInput
          source="isAppOnly"
          label="bo.stores.edit.fields.appOnly.label"
          initialValue={false}
          disabled={!affiliateProgramChosen}
        />
      </Box>
      <Box display="flex" flexDirection="row" justifyContent="space-between">
        <Box width="48%" display="flex" flexDirection="column" justifyContent="space-between">
          <BooleanInput
            source="isGreenDelivery"
            label="bo.stores.edit.fields.greenDelivery.label"
            initialValue={false}
            disabled={!affiliateProgramChosen}
          />
          <NumberInput
            source="greenKmReward"
            label="bo.stores.edit.fields.greenKmReward.label"
            initialValue={0}
            disabled={!affiliateProgramChosen || !isGreenDelivery}
          />
          <small>{t('bo.stores.edit.fields.commissionRate.info')}</small>
          <NumberInput
            source="commissionRate"
            label="bo.stores.edit.fields.commissionRate.label"
            helperText="bo.stores.edit.fields.commissionRate.helper"
            disabled={!affiliateProgramChosen}
          />
          <SelectArrayInput
            source="countries"
            label="bo.stores.edit.fields.countries.label"
            choices={countries}
            // disabled={!affiliateProgramChosen}
            // disabled // so they don't play with forbidden things!!!!
          />
        </Box>
        <Box width="48%" display="flex" flexDirection="column" justifyContent="center">
          <TextInput key="landscapeBannerId" source="landscapeBannerId" className={classes.hidden} disabled />
          <ImageInputUploader
            source="landscapeBanner"
            label="bo.stores.edit.fields.landscapeBanner.label"
            orientation="landscape"
            onChange={onImageChange}
          />
        </Box>
      </Box>
      <NumberInput source="weight" initialValue={-1} className={classes.hidden} />
    </Box>
  );
};

export default StoreMainContent;
