/* eslint-disable @typescript-eslint/camelcase */
const resources: { [res: string]: string } = {
  // Blog
  posts: 'admin/posts',

  // Shop
  'affiliate-programs': 'shop/admin/affiliate-programs',
  stores: 'shop/admin/stores',
  'stores-transaction': 'shop/admin/subscriptions',

  // Promo
  promos: 'promos/admin/promos',
  subscriptions: 'promos/admin/subscriptions/donation',
  'promotions-transaction': 'shop/admin/subscriptions',

  // Donations
  donations: 'promos/admin/donations',

  // Donations transaction
  'donations-transaction': 'promos/admin/donations/manageable_donations',
  'donations-subscriptions': 'promos/admin/subscriptions/donation',
  'donations-subscriptions-update': 'promos/admin/subscriptions',

  // Categories
  categories: 'categories/admin/categories',

  // Users
  admin_users: 'users/admin/admin_users',
  users: 'users/admin/users',

  // Accounts
  accounts: 'wallet/admin/accounts',
};

export default resources;
