import React, { FC, useState, SyntheticEvent, useEffect, ReactNode } from 'react';
import IconButton from '@material-ui/core/IconButton';
import TitleIcon from '@material-ui/icons/Title';
import FormatListBulletedIcon from '@material-ui/icons/FormatListBulleted';
import FormatListNumberedIcon from '@material-ui/icons/FormatListNumbered';
import FormatQuoteIcon from '@material-ui/icons/FormatQuote';
import LinkIcon from '@material-ui/icons/Link';
import FormatBoldIcon from '@material-ui/icons/FormatBold';
import FormatItalicIcon from '@material-ui/icons/FormatItalic';
import FormatUnderlinedIcon from '@material-ui/icons/FormatUnderlined';
import ImageIcon from '@material-ui/icons/Image';
import VideoIcon from '@material-ui/icons/YouTube';
import TextCenterIcon from '@material-ui/icons/FormatAlignCenter';
import TextLeftIcon from '@material-ui/icons/FormatAlignLeft';
import TextRightIcon from '@material-ui/icons/FormatAlignRight';
import TextJustifyIcon from '@material-ui/icons/FormatAlignJustify';

interface StyleButtonInterface {
  onToggle: Function;
  style: any;
  active: boolean;
  label: string;
  readOnly: boolean;
}
const StyleButton: FC<StyleButtonInterface> = ({ onToggle, style, active, label, readOnly, ...props }) => {
  const composeClassName = (): string => {
    let className = 'RichEditor-styleButton';
    if (active) {
      className += ' RichEditor-activeButton';
    }
    return className;
  };
  const [className, setClassName] = useState(composeClassName());
  const onMouseDown = (e: SyntheticEvent): void => {
    if (readOnly) return;
    e.preventDefault();
    onToggle(style);
  };
  const getIcon = (label: string): ReactNode | null => {
    switch (label) {
      case 'title':
        return <TitleIcon />;
      case 'blockquote':
        return <FormatQuoteIcon />;
      case 'ul':
        return <FormatListBulletedIcon />;
      case 'ol':
        return <FormatListNumberedIcon />;
      case 'link':
        return <LinkIcon />;
      case 'bold':
        return <FormatBoldIcon />;
      case 'italic':
        return <FormatItalicIcon />;
      case 'underline':
        return <FormatUnderlinedIcon />;
      case 'image':
        return <ImageIcon />;
      case 'video':
        return <VideoIcon />;
      case 'center':
        return <TextCenterIcon />;
      case 'left':
        return <TextLeftIcon />;
      case 'right':
        return <TextRightIcon />;
      case 'justify':
        return <TextJustifyIcon />;
    }
  };

  useEffect(() => {
    setClassName(composeClassName());
  }, [active]);

  return (
    <IconButton className={className} onMouseDown={onMouseDown} {...props}>
      {getIcon(label)}
    </IconButton>
  );
};

export default StyleButton;
