import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  hidden: {
    display: 'none',
  },
  readOnly: {
    pointerEvents: 'none',
    opacity: 0.7,
  },
});

export default useStyles;
