import React, { FC } from 'react';
import { DeleteButton, useTranslate } from 'react-admin';

interface StoreDeleteButtonInterface {
  record?: any;
  label?: string;
  langKey: string;
  resource?: string;
}
const StoreDeleteButton: FC<StoreDeleteButtonInterface> = ({ record, label, langKey, resource, ...props }) => {
  const t = useTranslate();
  const resourceName = record?.[langKey].find((item: any) => item.active === true);
  const resourceTranslate = resourceName && `${t(`bo.${resource}.delete`)} ${resourceName.name}`;

  return (
    <DeleteButton
      confirmTitle={resourceTranslate}
      label={label}
      undoable={false}
      disabled={record?.public}
      record={record}
      resource={resource}
      {...props}
    />
  );
};
export default StoreDeleteButton;
