import React, { FC, useCallback, useState, useEffect, useContext } from 'react';
import { useForm, useFormState, AnyObject } from 'react-final-form';
import { Confirm, useCreate, useNotify, useRedirect } from 'react-admin';
import CreateToolbar from 'components/CreateToolbar';
import { SaveHandlerContext } from 'providers/saveHandlerProvider';

interface CreatePostToolbarProps {
  resource?: string;
  prepareData?: Function;
  validationErrorMessages: Function;
}

const CreatePostToolbar: FC<CreatePostToolbarProps> = ({
  resource,
  prepareData,
  validationErrorMessages,
  ...props
}) => {
  const [specifyDateOpen, setSpecifyDateOpen] = useState(false);
  const [canStartUpdate, setCanStartUpdate] = useState(false);
  const formState: AnyObject = useFormState();
  const form: AnyObject = useForm();
  const notify = useNotify();
  const redirectTo = useRedirect();
  const [create] = useCreate(resource);
  const { setAfterSave } = useContext(SaveHandlerContext);

  const save = useCallback(
    (values) => {
      create(
        {
          payload: {
            data: prepareData ? prepareData(values) : values,
          },
        },
        {
          onSuccess: () => {
            notify(`bo.${resource}.feedback.updateCompleted`, 'info');
            redirectTo(`/${resource}`);
          },
          onFailure: (e: { message: string[] }) => {
            notify(typeof e.message === 'string' ? e.message : e.message[0], 'warning');
          },
          undoable: false,
        },
      );
    },
    [create, notify, redirectTo],
  );

  const checkPublicationInfos = (): void => {
    const { published, publishedAt } = formState.values;
    if (published && !publishedAt) {
      setSpecifyDateOpen(true);
      return;
    }
    setCanStartUpdate(true);
  };

  const onPublicationInfosConfirm = async (): Promise<void> => {
    await form.change('publishedAt', new Date(Date.now()).toISOString());
    setAfterSave(false);
    setSpecifyDateOpen(false);
  };
  const onCloseInfo = (): void => {
    setAfterSave(false);
    setSpecifyDateOpen(false);
  };
  useEffect(() => {
    canStartUpdate && save(formState.values);
  }, [canStartUpdate, formState.values]);

  return (
    <>
      <CreateToolbar
        onSave={checkPublicationInfos}
        resource={resource}
        prepareData={prepareData}
        validationErrorMessages={validationErrorMessages}
        {...props}
      />
      <Confirm
        isOpen={specifyDateOpen}
        title={`bo.${resource}.feedback.publishedWithoutData.title`}
        content={`bo.${resource}.feedback.publishedWithoutData.question`}
        confirm="Yes"
        confirmColor="primary"
        cancel="Cancel"
        onConfirm={onPublicationInfosConfirm}
        onClose={onCloseInfo}
      />
    </>
  );
};

export default CreatePostToolbar;
