import React, { useState } from 'react';
import { useNotify } from 'react-admin';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import StyleButton from './StyleButton';
import ImageInputUploaderSimple from 'components/ImageInputUploaderSimple';
import { createImageEntity, uploadToS3 } from 'utils';

const ImageAdd = (props: any) => {
  const [url, setUrl] = useState('');
  const notify = useNotify();

  const [linkDialogOpen, setLinkDialogOpen] = useState(false);
  const [isUploading, setIsUploading] = useState(false);

  const changeUrl = (evt: any) => {
    setUrl(evt.target.value);
  };

  const blur = (): void => {
    const tmp = document.createElement('input');
    document.body.appendChild(tmp);
    tmp.focus();
    document.body.removeChild(tmp);
  };

  const onOpen = (): void => {
    blur();
    setUrl('');
    setLinkDialogOpen(true);
  };

  const onClose = (): void => {
    setLinkDialogOpen(false);
  };

  const addImage = (): void => {
    const { editorState, onChange } = props;
    onChange(props.modifier(editorState, url));
    onClose();
  };

  const onImageChange = (id: string): void => {
    const path = `${process.env.REACT_APP_ASSET_STORE}/original/${id}`;
    setUrl(path);
  };

  const handleImageChange = async (
    file: File & { path: string; rawFile: File | undefined },
  ): Promise<undefined | null> => {
    if (!file) return null;
    setIsUploading(true);

    const { path } = file;

    try {
      const imageData: { signedUrl: string; id: string } = await createImageEntity({
        fileName: path,
      });

      if (imageData.signedUrl) {
        await uploadToS3(imageData?.signedUrl, file);

        onImageChange(imageData.id);
      } else {
        notify(`bo.general.errors.upload`, 'warning');
      }
    } catch (error) {
      notify(`bo.general.errors.upload`, 'warning');
    }

    setTimeout(() => {
      setIsUploading(false);
    }, 1000);
  };

  return (
    <>
      <StyleButton key="image" active={false} label="image" style="image" onToggle={onOpen} readOnly={false} />
      <Dialog open={linkDialogOpen} onClose={onClose} maxWidth="lg">
        <DialogTitle id="form-dialog-title">Image manager</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Insert image URL"
            fullWidth
            onChange={changeUrl}
            value={url}
          />
        </DialogContent>
        <Box padding="8px 24px">or</Box>
        <ImageInputUploaderSimple isUploading={isUploading} handleImageChange={handleImageChange} />
        <DialogActions>
          <Button onClick={onClose} color="primary">
            Cancel
          </Button>
          <Button onClick={addImage} color="primary" disabled={isUploading}>
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ImageAdd;
