import React, { FC } from 'react';
import { Box, Divider } from '@material-ui/core';
import {
  AutocompleteArrayInput,
  BooleanInput,
  DateTimeInput,
  NumberInput,
  ReferenceArrayInput,
  SelectArrayInput,
  TextInput,
  useTranslate,
} from 'react-admin';
import { useForm, useFormState } from 'react-final-form';

import Category from 'interfaces/Category';
import Promos from 'interfaces/Promos';
import countries from 'utils/countries';

import ImageInputUploader from 'components/ImageInputUploader';
import PromosCodeEdit from 'components/PromosCodeEdit';
import PromosCodeCreate from 'components/PromosCodeCreate';
import { usePromoCodes } from 'providers/promoCodesProvider';
import useStyles from './styles';

interface IPromosMainContent {
  editMode?: boolean;
  record?: Promos;
  codesState?: object;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const PromosMainContent: FC<IPromosMainContent> = ({ record, editMode }) => {
  const classes = useStyles();
  const form = useForm();
  const formState = useFormState();
  const { currentCSVCodes } = usePromoCodes();
  const t = useTranslate();

  const typeSelected = formState?.values?.promoCodeType;
  const isPublic = formState?.values?.public;
  const enableTransactionTracking: boolean = formState?.values?.enableTransactionTracking;

  const onImageChange = (id: string, orientation: string): void => {
    form.change(`${orientation}BannerId`, id);
  };
  const showCategoryEN = (choice: Category): string => {
    const findEn = choice?.localizations?.find((item) => item.languageCode === 'en');
    return `${findEn?.languageCode?.toUpperCase()} - ${findEn?.name}`;
  };

  return (
    <Box display="flex" flexDirection="column" justifyContent="flex-start" alignContent="flex-start">
      <BooleanInput source="public" label="bo.promos.edit.fields.public.label" initialValue={false} />
      <TextInput label="bo.promos.edit.fields.partnerName.label" source="partnerName" fullWidth required />
      <ReferenceArrayInput
        label="bo.promos.edit.fields.categories.label"
        source="categories"
        reference="categories"
        resource="categories"
        required
        perPage={200000}
        parse={(list: string[] = []) => list.map((id) => ({ id }))}
        format={(list: Category[] = []) => list.map(({ id }) => id)}
      >
        <AutocompleteArrayInput optionText={showCategoryEN} fullWidth />
      </ReferenceArrayInput>
      <Box display="flex" flexDirection="row" justifyContent="space-between" mb={4}>
        <Box width="48%" display="flex" flexDirection="column" justifyContent="space-between">
          <DateTimeInput source="expirationDate" label="bo.promos.edit.fields.expirationDate" />
          <NumberInput source="price" label="bo.promos.edit.fields.greenKmPrice.label" required initialValue={0} />
          <SelectArrayInput source="countries" label="bo.stores.edit.fields.countries.label" choices={countries} />
        </Box>
        <Box width="48%" display="flex" flexDirection="column" justifyContent="space-between">
          <Box width="100%" display="flex" flexDirection="column" justifyContent="space-between">
            {editMode ? (
              <PromosCodeEdit
                CSVCodes={currentCSVCodes?.data}
                typeSelected={typeSelected}
                promoCodeType={record?.promoCodeType}
                promoId={record?.id}
                isPublic={isPublic}
              />
            ) : (
              <PromosCodeCreate typeSelected={typeSelected} />
            )}

            <>
              {typeSelected === 'link' && (
                <>
                  <BooleanInput
                    source="enableTransactionTracking"
                    label="bo.promos.edit.fields.enableTransactionTracking.label"
                    initialValue={false}
                    disabled={typeSelected !== 'link'}
                  />

                  <small>{t('bo.promos.edit.fields.commissionRate.info')}</small>
                  <NumberInput
                    source="transactionCommissionRate"
                    label="bo.promos.edit.fields.transactionCommissionRate.label"
                    helperText="bo.promos.edit.fields.commissionRate.helper"
                    initialValue={0}
                    disabled={!enableTransactionTracking && typeSelected !== 'link'}
                  />
                </>
              )}
            </>
          </Box>
        </Box>
      </Box>
      <Divider />
      <Box display="flex" flexDirection="row" justifyContent="space-between" mt={4}>
        <Box width="48%">
          <TextInput key="portraitBannerId" source="portraitBannerId" className={classes.hidden} disabled />
          <ImageInputUploader
            source="portraitBanner"
            label="bo.promos.edit.fields.portraitBanner.label"
            orientation="portrait"
            onChange={onImageChange}
          />
        </Box>
        <Box width="48%">
          <TextInput key="landscapeBannerId" source="landscapeBannerId" className={classes.hidden} disabled />
          <ImageInputUploader
            source="landscapeBanner"
            label="bo.promos.edit.fields.landscapeBanner.label"
            orientation="landscape"
            onChange={onImageChange}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default PromosMainContent;
