import { Language } from 'interfaces/Language';

const languages: Language[] = [
  { id: 'en', name: 'English' },
  { id: 'it', name: 'Italiano' },
  { id: 'de', name: 'Deutsch' },
  { id: 'fr', name: 'Français' },
  { id: 'es', name: 'Español' },
];

const getLanguageName = (languageCode: string): string | undefined =>
  languages.find((lang) => lang.id === languageCode)?.name;
export { getLanguageName };

export default languages;
