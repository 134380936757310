import React, { FC } from 'react';
import { useHistory } from 'react-router-dom';

import {
  List,
  Datagrid,
  TextField,
  ReferenceField,
  ArrayField,
  SingleFieldList,
  EditButton,
  sanitizeListRestProps,
  CreateButton,
  Button,
  TopToolbar,
} from 'react-admin';

import SortIcon from '@material-ui/icons/Sort';

import HighlightField from 'components/HighlightField';
import NameChipField from 'components/NameChipField';
import ColourfulBooleanField from 'components/ColourfulBooleanField';
import StoreDeleteButton from 'components/StoreDeleteButton';

interface ListActionsInterface {
  basePath?: string;
}

const ListActions: FC<ListActionsInterface> = ({ basePath, ...rest }) => {
  const history = useHistory();
  return (
    <TopToolbar {...sanitizeListRestProps(rest)}>
      <CreateButton basePath={basePath} />
      <Button label="Sort Highlighted Stores" onClick={() => history.push('stores/sort-highlighted')}>
        <SortIcon />
      </Button>
    </TopToolbar>
  );
};

const StoreList: FC = (props) => {
  return (
    <List exporter={false} actions={<ListActions />} {...props}>
      <Datagrid rowClick="edit">
        <HighlightField source="isHighlight" addLabel label="bo.stores.list.highlight" />
        <ColourfulBooleanField source="public" label="bo.stores.list.public" />
        <ArrayField source="localizations" sortable={false} label="bo.stores.list.names">
          <SingleFieldList linkType={false}>
            <NameChipField />
          </SingleFieldList>
        </ArrayField>
        <ReferenceField
          source="affiliateProgramId"
          reference="affiliate-programs"
          label="bo.stores.list.affiliateProgram"
          link={false}
        >
          <TextField source="name" variant="subtitle2" />
        </ReferenceField>

        <ColourfulBooleanField source="isAppOnly" label="bo.stores.list.appOnly" />
        <ColourfulBooleanField source="isGreenDelivery" label="bo.stores.list.greenDelivery" />
        <EditButton label="bo.stores.list.edit" />
        <StoreDeleteButton langKey="localizations" label="ra.action.delete" />
      </Datagrid>
    </List>
  );
};

export default StoreList;
