import React, { createContext, FC, useState } from 'react';

interface SaveHandlerContextProps {
  afterSave: boolean;
  isUploadingImage: boolean;
  setAfterSave: Function;
  setIsUploadingImageLandscape: Function;
  setIsUploadingImagePortrait: Function;
}

const SaveHandlerContext = createContext<SaveHandlerContextProps>({} as SaveHandlerContextProps);

const SaveHandlerContextProvider: FC = ({ children }) => {
  const [isUploadingImageLandscape, setIsUploadingImageLandscape] = useState(false);
  const [isUploadingImagePortrait, setIsUploadingImagePortrait] = useState(false);
  const [afterSave, setAfterSave] = useState(false);
  const isUploadingImage = isUploadingImageLandscape || isUploadingImagePortrait;
  return (
    <SaveHandlerContext.Provider
      value={{
        afterSave,
        setAfterSave,
        isUploadingImage,
        setIsUploadingImageLandscape,
        setIsUploadingImagePortrait,
      }}
    >
      {children}
    </SaveHandlerContext.Provider>
  );
};

export { SaveHandlerContext, SaveHandlerContextProvider };
