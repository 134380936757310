import Keycloak from 'keycloak-js';
class AuthProvider {
  private static instance: AuthProvider;
  private keycloak: Keycloak.KeycloakInstance;
  public initialized = false;

  private config: any = {
    url: process.env.REACT_APP_KEYCLOAK_URL,
    realm: process.env.REACT_APP_KEYCLOAK_REALM,
    clientId: process.env.REACT_APP_KEYCLOAK_CLIENT_ID,
    onLoad: 'login-required',
    'ssl-required': process.env.REACT_APP_KEYCLOAK_SSL_REQUIRED,
    resource: process.env.REACT_APP_KEYCLOAK_RESOURCE,
    'public-client': process.env.REACT_APP_KEYCLOAK_PUBLIC_CLIENT,
    'confidential-port': process.env.REACT_APP_KEYCLOAK_CONFIDENTIAL_PORT,
  };

  private initOptions: Keycloak.KeycloakInitOptions = {
    onLoad: 'login-required',
    enableLogging: true,
    responseMode: 'query',
  };

  public static getInstance(): AuthProvider {
    if (!AuthProvider.instance) {
      AuthProvider.instance = new AuthProvider();
    }

    return AuthProvider.instance;
  }

  private constructor() {
    this.keycloak = Keycloak(this.config);
  }

  public init = async (): Promise<void> => {
    if (!this.initialized) {
      await this.initializeKeycloak();
      this.startTokenPeriodicCheck();
    }
  };

  private startTokenPeriodicCheck = (): void => {
    setInterval(async () => {
      try {
        await this.keycloak.updateToken(30);
      } catch (error) {
        this.keycloak.logout();
      }
    }, 5000);
  };

  public getToken = (): string | undefined => this.keycloak.token;

  private initializeKeycloak = async (): Promise<void> => {
    const authenticated = await this.keycloak.init(this.initOptions);
    if (!authenticated) this.keycloak.login();
    this.initialized = true;
  };

  public logout = (): void => {
    this.keycloak.logout();
  };
  public checkAuth = (): Promise<boolean | undefined> => Promise.resolve(this.keycloak.authenticated);
  public checkError = (): Promise<void> => Promise.resolve();
  public getPermissions = (): Promise<void> => Promise.resolve();
}

export default AuthProvider;
