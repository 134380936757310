import React, { FC, useEffect, useRef, useState } from 'react';
import {
  List,
  Datagrid,
  TextField,
  ReferenceField,
  DateField,
  TopToolbar,
  useTranslate,
  sanitizeListRestProps,
  Loading,
  FunctionField,
} from 'react-admin';

import { DialogTitle, DialogContent, DialogContentText, Button, Dialog } from '@material-ui/core';

import { CSVLink } from 'react-csv';
import HttpClient from 'providers/httpClient';

import DownloadIcon from '@material-ui/icons/GetApp';
import StatusChip from 'components/StatusChipField';
import useStyles from './styles';
interface ICurrencyFiled {
  source: string;
  record?: any;
  sourceCurrency: string;
  label?: string;
}

const CurrencyFiled: FC<ICurrencyFiled> = ({ record, source, sourceCurrency }) => {
  const amount = record ? record[source] : 0;
  const formatCurr = new Intl.NumberFormat('it-IT', { style: 'currency', currency: record?.[sourceCurrency] }).format(
    amount,
  );
  return <div>{formatCurr}</div>;
};

interface ListActionsInterface {
  total?: number;
  csvHandler: any;
}
const ListActions: FC<ListActionsInterface> = ({ csvHandler, ...props }) => {
  const t = useTranslate();
  const classes = useStyles();

  return (
    <TopToolbar {...sanitizeListRestProps(props)}>
      <Button onClick={csvHandler} className={classes.csvButton} startIcon={<DownloadIcon />}>
        {t('resources.stores-transaction.export').toUpperCase()}
      </Button>
    </TopToolbar>
  );
};

const StoresTransactionList: FC = (props) => {
  const [csvData, setCsvData] = useState('');
  const [open, setOpen] = useState(false);
  const classes = useStyles();
  const t = useTranslate();

  const csvHandler = (): void => {
    setOpen(true);
    HttpClient(`${process.env.REACT_APP_BASE_API_URL}/shop/admin/subscriptions/export`, {
      method: 'GET',
    }).then((res: any) => {
      setCsvData(res?.body);
    });
  };
  const handleToggleModal = (): void => {
    setOpen(!open);
  };

  const formatCurr = (currency: string, amount: number) =>
    new Intl.NumberFormat('it-IT', { style: 'currency', currency: currency }).format(amount);

  return (
    <List
      {...props}
      actions={<ListActions csvHandler={csvHandler} />}
      bulkActionButtons={false}
      filter={{ originType: 'NONE' }}
    >
      <Datagrid>
        <Dialog
          open={open}
          onClose={handleToggleModal}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          <DialogTitle>{t('resources.stores-transaction.ready')}</DialogTitle>
          {csvData ? (
            <DialogContent>
              <DialogContentText>
                <CSVLink data={csvData} filename={'store-transaction.csv'} className={classes.csvDownload}>
                  {t('resources.stores-transaction.download').toUpperCase()}
                </CSVLink>
              </DialogContentText>
            </DialogContent>
          ) : (
            <Loading />
          )}
        </Dialog>
        <ReferenceField
          source="store.id"
          reference="stores"
          link="edit"
          label="resources.stores-transaction.fields.name"
        >
          <TextField source="localizations[0].name" />
        </ReferenceField>
        <ReferenceField
          source="subscribedBy"
          reference="users"
          link="show"
          label="resources.stores-transaction.fields.subscribedBy"
        >
          <TextField source="email" />
        </ReferenceField>
        <DateField source="transactionDate" showTime label="resources.stores-transaction.fields.date" />

        <CurrencyFiled source="saleAmount" sourceCurrency="saleCurrency" />
        <CurrencyFiled source="commissionAmount" sourceCurrency="commissionCurrency" />

        <FunctionField
          label="resources.stores-transaction.fields.cashback"
          render={(record: { commissionAmount: string; commissionCurrency: string }) =>
            `${formatCurr(record?.commissionCurrency, Number(record?.commissionAmount) / 2)}`
          }
        />

        <FunctionField
          label="resources.stores-transaction.fields.gocceGenerated"
          render={(record: { commissionAmount: string }) => `${(Number(record.commissionAmount) / 2) * 100}`}
        />

        <StatusChip source="commissionStatus" />
      </Datagrid>
    </List>
  );
};

export default StoresTransactionList;
