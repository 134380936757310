import React, { FC } from 'react';
import { useParams } from 'react-router-dom';
import { useQuery, useNotify, Button, useMutation, useRefresh, useTranslate } from 'react-admin';
import useStyles from './styles';

interface ISubApproveBtn {
  record: any;
  action: string;
}

const SubActionButton: FC<ISubApproveBtn> = ({ record, action }) => {
  const notify = useNotify();
  const refresh = useRefresh();
  const t = useTranslate();
  const classes = useStyles();

  const updatedRecord = {
    ...record,
    transactionState: action,
  };

  const [update, { loading }] = useMutation(
    {
      type: 'donations-subscriptions-update',
      resource: 'donations-subscriptions-update',
      payload: updatedRecord,
    },
    {
      onSuccess: ({ data }: any) => {
        if (data) {
          /**
           * TODO
           * useRefresh seems to be not working as expected
           * @see https://github.com/marmelab/react-admin/issues/4789
           */
          setTimeout(() => {
            refresh();
          }, 1000);
        }
        notify(t(`bo.donation_sub.actions.success.${action}`), 'info');
      },
      onFailure: (_error: any) => notify(t(`bo.donation_sub.actions.success.${action}`), 'warning'),
    },
  );

  const handleClick = (): void => {
    update();
  };

  const { data: wallet, loading: userDataLoading } = useQuery({
    type: 'get-user-wallet',
    resource: 'accounts',
    payload: { id: record.subscribedBy },
  });

  const { id } = useParams();

  const { data: donation, loading: donationDataLoading } = useQuery({
    type: 'get-donation',
    resource: 'donations',
    payload: { id: id },
  });

  const availableBalance =
    wallet && wallet.find((w: { currency: { code: string } }) => w.currency.code === 'GCN').availableBalance;
  const authorizedAmount =
    wallet && wallet.find((w: { currency: { code: string } }) => w.currency.code === 'GCN').authorizedAmount;
  const balance = Number(availableBalance);
  const authorizedBalance = Number(authorizedAmount);
  const donationAmount = donation?.price;
  const disabled = userDataLoading || donationDataLoading || donationAmount <= authorizedBalance + balance;
  // trx.amount <= authorizedBalance + availableBalance

  console.log('wallet: ', donationAmount, authorizedBalance, balance, donationAmount <= authorizedBalance + balance);

  return action === 'VOID' ? (
    <Button
      label={t(`bo.donation_sub.reject`)}
      onClick={handleClick}
      disabled={loading}
      className={classes.btnReject}
    />
  ) : (
    <Button
      label={t(`bo.donation_sub.approve`)}
      onClick={handleClick}
      disabled={loading || !disabled}
      variant="contained"
      color="primary"
      className={classes.btnApprove}
    />
  );
};

export default SubActionButton;
