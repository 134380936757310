import React, { FC, useEffect, useState } from 'react';
import { Admin, Resource } from 'react-admin';
import crudProvider from '@fusionworks/ra-data-nest-crud';
import withCustomizations from './providers/capabilities/withCustomizations';

import DescriptionIcon from '@material-ui/icons/Description';
import PeopleAltIcon from '@material-ui/icons/PeopleAlt';
import LocalOfferOutlinedIcon from '@material-ui/icons/LocalOfferOutlined';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';
import StorefrontIcon from '@material-ui/icons/Storefront';
import ClassIcon from '@material-ui/icons/Class';
import CompareArrowsIcon from '@material-ui/icons/CompareArrows';

import PostList from './pages/Posts/PostList';
import PostCreate from './pages/Posts/PostCreate';
import PostEdit from './pages/Posts/PostEdit';

import AdminUserList from './pages/AdminUsers/AdminUserList';
import AdminUserCreate from './pages/AdminUsers/AdminUserCreate';

import UserShow from './pages/Users/UserShow';
import UserList from './pages/Users/UserList';

import StoreCreate from './pages/Stores/StoreCreate';
import StoreList from './pages/Stores/StoreList';
import StoreEdit from './pages/Stores/StoreEdit';
import StoresTransactionList from './pages/StoresTransaction/StoresTransactionList';

import PromoList from './pages/Promos/PromoList';
import PromoCreate from './pages/Promos/PromoCreate';
import PromoEdit from './pages/Promos/PromoEdit';
import PromosTransactionList from './pages/PromosTransaction/PromosTransactionList';

import DonationsList from './pages/Donations/DonationsList';
import DonationsCreate from './pages/Donations/DonationsCreate';
import DonationsEdit from './pages/Donations/DonationsEdit';

import DonationsTransactionList from './pages/DonationsTransaction/DonationsTransactionList';

import CategoryList from './pages/Categories/CategoryList';
import CategoryCreate from './pages/Categories/CategoryCreate';
import CategoryEdit from './pages/Categories/CategoryEdit';

import AuthProvider from './providers/authProvider';
import HttpClient from './providers/httpClient';
import I18nextProvider from './providers/i18nextProvider';

import customRoutes from './customRoutes';

const dataProvider = crudProvider(process.env.REACT_APP_BASE_API_URL, HttpClient);
const DpWithCustomizations = withCustomizations(dataProvider);
const authProvider = AuthProvider.getInstance();
const i18nProvider = new I18nextProvider();

const App: FC = () => {
  const [authenticated, setAuthenticated] = useState(false);
  useEffect(() => {
    (async () => {
      await authProvider.init();
      setAuthenticated(true);
    })();
  }, []);

  if (authenticated)
    return (
      <Admin
        dataProvider={DpWithCustomizations}
        authProvider={authProvider}
        i18nProvider={i18nProvider}
        customRoutes={customRoutes}
        loginPage={false}
      >
        <Resource name="posts" edit={PostEdit} create={PostCreate} list={PostList} icon={DescriptionIcon} />
        <Resource name="stores" list={StoreList} create={StoreCreate} edit={StoreEdit} icon={StorefrontIcon} />
        <Resource name="stores-transaction" list={StoresTransactionList} icon={CompareArrowsIcon} />

        <Resource name="promos" list={PromoList} create={PromoCreate} edit={PromoEdit} icon={LocalOfferOutlinedIcon} />
        <Resource name="promotions-transaction" list={PromosTransactionList} icon={CompareArrowsIcon} />

        <Resource
          name="donations"
          list={DonationsList}
          create={DonationsCreate}
          edit={DonationsEdit}
          icon={FavoriteBorderIcon}
        />
        <Resource name="donations-transaction" list={DonationsTransactionList} icon={CompareArrowsIcon} />

        <Resource name="categories" list={CategoryList} create={CategoryCreate} edit={CategoryEdit} icon={ClassIcon} />
        <Resource name="users" list={UserList} show={UserShow} icon={PeopleAltIcon} />
        <Resource name="admin_users" list={AdminUserList} create={AdminUserCreate} icon={VerifiedUserIcon} />
        <Resource name="affiliate-programs" />
        <Resource name="donations-subscriptions" />
        <Resource name="donations-subscriptions-update" />
      </Admin>
    );
  else return <></>;
};

export default App;
