import React, { FC, useState, useEffect } from 'react';
import { useInput, Labeled } from 'react-admin';
import { EditorState, ContentState, convertToRaw, CompositeDecorator, ContentBlock } from 'draft-js';

import { Typography } from '@material-ui/core';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';

import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

import createImagePlugin from 'draft-js-image-plugin';

import 'draft-js/dist/Draft.css';
import './draft.css';
import ImageAdd from './ImageAdd';

interface RichTextInputInterface {
  disabled?: boolean;
  label: string;
  source?: string;
  required?: boolean;
  name: string;
  fullWidth?: boolean;
}

const RichTextInput: FC<RichTextInputInterface> = ({ disabled = false, label, fullWidth = false, ...props }) => {
  const {
    input: { value, onChange },
    meta: { error },
  } = useInput(props);

  const findImageEntities = (
    contentBlock: ContentBlock,
    callback: (start: number, end: number) => void,
    contentState: ContentState,
  ): void => {
    contentBlock.findEntityRanges((character) => {
      const entityKey = character.getEntity();
      return entityKey !== null && contentState.getEntity(entityKey).getType() === 'IMAGE';
    }, callback);
  };

  const Image: FC<{
    contentState: ContentState;
    entityKey: string;
  }> = ({ contentState, entityKey }) => {
    const { height, src, width } = contentState.getEntity(entityKey).getData();

    return <img src={src} height={height} width={width} />;
  };

  const [validationError] = useState(error);
  const decorator = new CompositeDecorator([
    {
      strategy: findImageEntities,
      component: Image,
    },
  ]);

  const blocksFromHTML = htmlToDraft(value);
  const state = ContentState.createFromBlockArray(blocksFromHTML.contentBlocks, blocksFromHTML.entityMap);

  const [editorState, setEditorState] = useState(
    value.trim() !== '' ? EditorState.createWithContent(state, decorator) : EditorState.createEmpty(),
  );

  const onEditorChange = (newEditorState: EditorState): void => {
    setEditorState(newEditorState);
  };

  useEffect(() => {
    const html = draftToHtml(convertToRaw(editorState.getCurrentContent()));
    onChange(html);
  }, [editorState]);

  const imagePlugin = createImagePlugin();

  return (
    <Labeled label={label} fullWidth={fullWidth}>
      <>
        <Editor
          editorState={editorState}
          wrapperClassName="demo-wrapper"
          editorClassName="demo-editor"
          onEditorStateChange={onEditorChange}
          toolbarCustomButtons={[
            <ImageAdd
              key="image"
              editorState={editorState}
              onChange={onEditorChange}
              modifier={imagePlugin.addImage}
            />,
            // <VideoAdd
            //   key="video"
            //   editorState={editorState}
            //   onChange={onEditorChange}
            //   modifier={videoPlugin.addVideo}
            // />,
          ]}
          toolbar={{
            options: [
              'inline',
              'blockType',
              // 'fontSize',
              // 'fontFamily',
              'list',
              'textAlign',
              // 'colorPicker',
              'link',
              'embedded',
              // 'emoji',
              // 'image',
              // 'remove',
              'history',
            ],
            embedded: {
              icon:
                'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAAu0lEQVQ4T6XTPYoCQRBA4c91MdhsAzFcxBsoewUz76ChB9HczHPoLQY0MZY1N9hIA1GQwplEZvCnCwo6qHrdXf26JjFqif0KQBs9NPP8xifq+QYXnPGPfZ4r/AWgjyUaL57mhEEAFrF4sbkoXwQgw28JYIvOA3AWgB1+SgrH2GCWz6eMtQvAAV8VgDk+MMQUrbu647OAESZVgOQrJA8x+RmTRYrBhsrdEpVD54jQ+F7ldaHymxLe2pJ/4xXg+is5KG5aLgAAAABJRU5ErkJggg==',
            },
            image: {
              alignmentEnabled: false,
            },
          }}
        />

        <Typography variant="caption" color="error">
          {validationError}
        </Typography>
      </>
    </Labeled>
  );
};

export default RichTextInput;
