import React, { FC, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import {
  LinearProgress,
  useGetList,
  useMutation,
  useNotify,
  useRedirect,
  useReference,
  Button,
  useTranslate,
} from 'react-admin';
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';
import arrayMove from 'array-move';

import Box from '@material-ui/core/Box';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Typography from '@material-ui/core/Typography';
import DragHandleIcon from '@material-ui/icons/DragHandle';
import SaveIcon from '@material-ui/icons/Save';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

import Store from 'interfaces/Store';
import StoreLocalization from 'interfaces/StoreLocalization';

import useStyles from './styles';

interface StylesInterface {
  sortableItem: string;
}

interface OnSortEndInterface {
  oldIndex: number;
  newIndex: number;
}

interface SortableContainerInterface {
  items: Store[];
  classes: StylesInterface;
}

interface SortableItemInterface {
  id?: string;
  text: string;
  affiliateProgramId: number;
  classes: StylesInterface;
}

const DragHandle = SortableHandle(() => (
  <ListItemIcon>
    <DragHandleIcon />
  </ListItemIcon>
));

const SortableItem = SortableElement(({ text, affiliateProgramId, classes }: SortableItemInterface) => {
  const { loaded, referenceRecord } = useReference({
    id: affiliateProgramId,
    reference: 'affiliate-programs',
  });

  return (
    <Box
      border={1}
      borderRadius="borderRadius"
      bgcolor="background.paper"
      borderColor="grey.500"
      className={classes.sortableItem}
    >
      <ListItem ContainerComponent="div">
        <ListItemText primary={!loaded ? <LinearProgress /> : `${text} - ${referenceRecord?.name}`} />

        <ListItemSecondaryAction>
          <DragHandle />
        </ListItemSecondaryAction>
      </ListItem>
    </Box>
  );
});

const SortableListContainer = SortableContainer(({ items, classes }: SortableContainerInterface) => {
  return (
    <List component="div">
      {items.map(({ id, localizations, affiliateProgramId }, index) => (
        <SortableItem
          key={id}
          index={index}
          text={localizations.filter((loc: StoreLocalization) => loc.active)?.[0]?.name ?? ''}
          affiliateProgramId={affiliateProgramId}
          classes={classes}
        />
      ))}
    </List>
  );
});

const SortableList: FC = () => {
  const classes = useStyles();
  const notify = useNotify();
  const redirectTo = useRedirect();
  const translate = useTranslate();
  const history = useHistory();
  const [items, setItems] = useState([]);
  const [update] = useMutation({
    type: 'update-sorting',
    resource: 'stores',
    payload: items.map((store: Store, index: number) => ({ id: store.id, weight: index })),
  });
  const { data, loading } = useGetList(
    'stores',
    { page: 1, perPage: 10 },
    { field: 'weight', order: 'ASC' },
    {
      'isHighlight||$eq': 'true', // That's really ugly but it's the only way that I found to filter on a boolean
    },
  );

  const onSortEnd = ({ oldIndex, newIndex }: OnSortEndInterface): void => {
    setItems((items) => arrayMove(items, oldIndex, newIndex));
  };

  const updateSorting = async (): Promise<void> => {
    await update();
    notify(`bo.stores.feedback.updateCompleted`, 'info');
    redirectTo(`/stores`);
  };

  useEffect(() => {
    if (!loading && items !== Object.values(data)) setItems(Object.values(data));
  }, [loading]);

  return (
    <>
      <Box display="flex" flexDirection="column" alignItems="flex-start" mt={2}>
        <Button label="bo.stores.sort.back" onClick={() => history.goBack()}>
          <ArrowBackIcon />
        </Button>
        <Typography variant="h4" gutterBottom>
          {translate(`bo.stores.sort.title`)}
        </Typography>
      </Box>
      <SortableListContainer
        helperClass={classes.helper}
        items={items}
        onSortEnd={onSortEnd}
        lockAxis="y"
        classes={classes}
      />
      <Box display="flex" justifyContent="flex-start">
        <Button label="bo.stores.sort.save" onClick={updateSorting}>
          <SaveIcon />
        </Button>
      </Box>
    </>
  );
};

export default SortableList;
