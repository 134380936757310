import React, { createContext, useReducer, FC, useContext, useState, useCallback, useEffect } from 'react';
import HttpClient from './httpClient';

interface CodesContextProps {
  currentCSVCodes: any;
  setCurrentCSVCodes: (codes: object) => void;
  page: number;
  setPage: (p: number) => void;
  promoId?: string;
  setPromoId: (pid: string) => void;
  getCSVcodes: (o: { promoId: string; page: string }) => void;
  limit?: number;
}

interface ICodeCSV {
  value?: string;
  totalSubscriptions?: boolean;
}
interface ICodeResponse {
  json?: ICodeCSV[];
}

const CodesContext = createContext<CodesContextProps>({} as CodesContextProps);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const reducer = (state = {}, action: any): object => {
  switch (action.type) {
    case 'setCodes':
      return action.payload;
    case 'resetCodes':
      return {};

    default:
      return state;
  }
};

const CodesContextProvider: FC = ({ children }) => {
  const [currentCSVCodes, setCurrentCSVCodes] = useReducer(reducer, {});
  const [page, setPage] = useState(0);
  const [promoId, setPromoId] = useState('');
  const [limit] = useState(25);

  // chiamata per ottenere i codici
  const getCSVcodes = useCallback(
    async ({ promoId, page }): Promise<void> => {
      const response: ICodeResponse = await HttpClient(
        `${process.env.REACT_APP_BASE_API_URL}/promos/admin/promos/${promoId}/codes?limit=${limit}&page=${page + 1}`,
        {
          method: 'GET',
        },
      );
      setCurrentCSVCodes({
        type: 'setCodes',
        payload: response.json,
      });
    },
    [promoId],
  );

  useEffect(() => {
    promoId && getCSVcodes({ promoId, page });
  }, [promoId]);

  return (
    <CodesContext.Provider
      value={{
        currentCSVCodes,
        setCurrentCSVCodes,
        page,
        setPage,
        promoId,
        setPromoId,
        getCSVcodes,
        limit,
      }}
    >
      {children}
    </CodesContext.Provider>
  );
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const usePromoCodes = (): any => {
  const context = useContext(CodesContext);

  if (!context) {
    throw new Error('usePromoCodes must be used within an AppGlobalProvider.');
  }

  return context;
};

export { CodesContext, CodesContextProvider, usePromoCodes };
