import React, { FC, useContext, useEffect } from 'react';
import { Edit, TabbedForm, FormTab, useTranslate } from 'react-admin';

import EditPostToolbar from 'components/EditPostToolbar';
import PostContent from 'components/PostContent';
import PostMainContent from 'components/PostMainContent';
import GbLoader from 'components/GbLoader';

import { preparePostData } from 'utils';
import languageCode from 'utils/languages';
import map from 'lodash/map';
import Post from 'interfaces/Post';
import { SaveHandlerContext } from 'providers/saveHandlerProvider';

import validatePost, { validationErrorMessages } from './validatePost';

const PostEdit: FC = (props) => {
  const translate = useTranslate();
  const { afterSave, setAfterSave } = useContext(SaveHandlerContext);

  // Unmount component will reset the loading global state
  useEffect(() => {
    return () => setAfterSave(false);
  }, []);
  return (
    <Edit {...props}>
      <TabbedForm
        className="relative"
        toolbar={<EditPostToolbar prepareData={preparePostData} validationErrorMessages={validationErrorMessages} />}
        validate={(post: Post) => validatePost(post, translate)}
      >
        <FormTab label="bo.posts.edit.tabs.main" key="main">
          {afterSave && <GbLoader />}
          <PostMainContent />
        </FormTab>

        {map(languageCode, (lang, index) => {
          return (
            <FormTab label={lang.name} key={lang.id}>
              {afterSave && <GbLoader />}
              <PostContent lang={lang} index={index} />
            </FormTab>
          );
        })}
      </TabbedForm>
    </Edit>
  );
};
export default PostEdit;
