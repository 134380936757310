import Post from 'interfaces/Post';
import { omit, compact, flatten, each, isEmpty } from 'lodash';
import { getLanguageName } from 'utils/languages';
import PostContent from 'interfaces/PostContent';

const TITLE_MIN_LENGTH = 3;
const ABSTRACT_MAX_LENGTH = 255;
const CONTENT_MIN_LENGTH = 3;
const METADESCRIPTION_MAX_LENGTH = 255;
const METAKEYWORDS_MAX_LENGTH = 255;

interface PostContentValidationError {
  title?: string;
  abstract?: string;
  content?: string;
  metaDescription?: string;
  metaKeywords?: string;
}

interface PostValidationErrors {
  localizations?: string;
  categories?: string;
  portraitBanner?: string;
  landscapeBanner?: string;
  postContents?: PostContentValidationError[];
}

const validateLocalizationsQty = (post: Post): string | undefined => {
  if (
    (post?.postContents?.length ?? 0) === 0 ||
    (post?.postContents
      ?.map((content) => omit(content, ['languageCode']))
      .filter((content) => content.active)
      .filter((content) => Object.keys(content).length > 0).length ?? 0) === 0
  ) {
    return 'bo.posts.edit.fields.localizations.validation';
  }
};

const validateCategories = (post: Post): string | undefined =>
  isEmpty(post.categories) ? 'bo.posts.edit.fields.categories.validation' : undefined;

const validatePortaitBanner = (post: Post): string | undefined =>
  isEmpty(post.portraitBanner) ? 'bo.posts.edit.fields.portraitBanner.validation' : undefined;

const validateLandscapeBanner = (post: Post): string | undefined =>
  isEmpty(post.landscapeBanner) ? 'bo.posts.edit.fields.landscapeBanner.validation' : undefined;

const validateTitle = (postContent: PostContent, translate: Function): string | undefined => {
  if (isEmpty(postContent.title))
    return translate('bo.posts.edit.fields.title.validation.required', {
      language: getLanguageName(postContent?.languageCode ?? ''),
    });

  if ((postContent?.title?.length ?? 0) < TITLE_MIN_LENGTH)
    return translate('bo.posts.edit.fields.title.validation.minLength', {
      language: getLanguageName(postContent?.languageCode ?? ''),
      minLength: TITLE_MIN_LENGTH,
    });
};

const validateAbstract = (postContent: PostContent, translate: Function): string | undefined => {
  if (isEmpty(postContent.abstract))
    return translate('bo.posts.edit.fields.abstract.validation.required', {
      language: getLanguageName(postContent?.languageCode ?? ''),
    });

  if ((postContent?.abstract?.length ?? 0) > ABSTRACT_MAX_LENGTH)
    return translate('bo.posts.edit.fields.abstract.validation.maxLength', {
      language: getLanguageName(postContent?.languageCode ?? ''),
      maxLength: ABSTRACT_MAX_LENGTH,
    });
};

const validateContent = (postContent: PostContent, translate: Function): string | undefined => {
  if (isEmpty(postContent.content))
    return translate('bo.posts.edit.fields.content.validation.required', {
      language: getLanguageName(postContent?.languageCode ?? ''),
    });

  if ((postContent?.content?.length ?? 0) < CONTENT_MIN_LENGTH)
    return translate('bo.posts.edit.fields.content.validation.minLength', {
      language: getLanguageName(postContent?.languageCode ?? ''),
      minLength: CONTENT_MIN_LENGTH,
    });
};

const validateMetaDescription = (postContent: PostContent, translate: Function): string | undefined => {
  if ((postContent?.metaDescription?.length ?? 0) > METADESCRIPTION_MAX_LENGTH)
    return translate('bo.posts.edit.fields.metaKeywords.validation.maxLength', {
      language: getLanguageName(postContent?.languageCode ?? ''),
      minLength: METADESCRIPTION_MAX_LENGTH,
    });
};

const validateMetaKeywords = (postContent: PostContent, translate: Function): string | undefined => {
  if ((postContent?.metaKeywords?.length ?? 0) > METAKEYWORDS_MAX_LENGTH)
    return translate('bo.posts.edit.fields.metaKeywords.validation.maxLength', {
      language: getLanguageName(postContent?.languageCode ?? ''),
      minLength: METAKEYWORDS_MAX_LENGTH,
    });
};

const validatePostContent = (postContent: PostContent, translate: Function): any => ({
  title: postContent.active ? validateTitle(postContent, translate) : undefined,
  abstract: postContent.active ? validateAbstract(postContent, translate) : undefined,
  content: postContent.active ? validateContent(postContent, translate) : undefined,
  metaDescription: postContent.active ? validateMetaDescription(postContent, translate) : undefined,
  metaKeywords: postContent.active ? validateMetaKeywords(postContent, translate) : undefined,
});

const validatePost = (post: Post, translate: Function): PostValidationErrors => ({
  categories: validateCategories(post),
  portraitBanner: validatePortaitBanner(post),
  landscapeBanner: validateLandscapeBanner(post),
  postContents: compact(post.postContents?.map((content: PostContent) => validatePostContent(content, translate))),
  localizations: validateLocalizationsQty(post),
});

export const validationErrorMessages = (post: Post, translate: Function): string[] => {
  let errors = [
    validateCategories(post),
    validatePortaitBanner(post),
    validateLandscapeBanner(post),
    validateLocalizationsQty(post),
  ];

  each(compact(post.postContents), (postContent: PostContent) => {
    errors = errors.concat(Object.values(validatePostContent(postContent, translate)));
  });

  return compact(flatten(errors));
};

export default validatePost;
