import React, { FC, ReactNode } from 'react';
import { useTranslate } from 'react-admin';
import Typography from '@material-ui/core/Typography';

interface SectionTitleInterface {
  label: string;
  children?: ReactNode;
}

const SectionTitle: FC<SectionTitleInterface> = ({ label }) => {
  const translate = useTranslate();

  return (
    <Typography variant="h6" gutterBottom>
      {translate(label)}
    </Typography>
  );
};

export default SectionTitle;
