import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  dropzone: {
    cursor: 'pointer',
    padding: '8px 24px',
  },
  dropzoneContent: {
    padding: '30px',
    background: '#fafafa',
  },
});

export default useStyles;
