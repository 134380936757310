import React, { FC, useState, useContext } from 'react';
import { ImageInput, ImageField, useNotify } from 'react-admin';
import { useForm } from 'react-final-form';
import { createImageEntity, uploadToS3 } from 'utils';
import ImageInputUploaderProps from 'interfaces/ImageInputUploader';
import { SaveHandlerContext } from 'providers/saveHandlerProvider';

const ImageInputUploader: FC<ImageInputUploaderProps> = ({ source, label, orientation, onChange }) => {
  const notify = useNotify();
  const [isUploading, setIsUploading] = useState(false);
  const form = useForm();
  const { setIsUploadingImageLandscape, setIsUploadingImagePortrait } = useContext(SaveHandlerContext);

  const handleImageChange = async (
    file: File & { path: string; rawFile: File | undefined },
  ): Promise<undefined | null> => {
    if (!file) return null;
    orientation === 'landscape' ? setIsUploadingImageLandscape(true) : setIsUploadingImagePortrait(true);
    setIsUploading(true);

    const { path } = file;

    try {
      const imageData: { signedUrl: string; id: string } = await createImageEntity({
        fileName: path,
      });

      if (imageData.signedUrl) {
        await uploadToS3(imageData?.signedUrl, file);

        onChange(imageData.id, orientation);
      } else {
        notify(`bo.posts.edit.fields.${orientation}Banner.errors.upload`, 'warning');
        form.change(`${orientation}Banner`, undefined);
      }
    } catch (error) {
      notify(`bo.posts.edit.fields.${orientation}Banner.errors.upload`, 'warning');
      form.change(`${orientation}Banner`, undefined);
    }

    setIsUploading(false);
    orientation === 'landscape' ? setIsUploadingImageLandscape(false) : setIsUploadingImagePortrait(false);
  };

  return (
    <ImageInput
      source={source}
      label={label}
      accept="image/*"
      disabled="true"
      onDrop={handleImageChange}
      onChange={handleImageChange}
    >
      <ImageField source="path" title="title" style={{ opacity: isUploading ? 0.5 : 1 }} />
    </ImageInput>
  );
};

export default ImageInputUploader;
