import AuthProvider from './authProvider';
import { fetchUtils } from 'react-admin';

interface HttpClientOptions {
  headers: Headers;
}
const HttpClient = (url: string, options: any): Promise<object> => {
  const token = AuthProvider.getInstance().getToken();
  if (!options.headers) {
    options.headers = new Headers({ Accept: 'application/json' });
  }
  if (token) options.headers.set('Authorization', `Bearer ${token}`);
  return fetchUtils.fetchJson(url, options);
};

export default HttpClient;
