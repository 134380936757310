import { compact, flatten, each, isEmpty, omit } from 'lodash';
import { getLanguageName } from 'utils/languages';
import StoreLocalization from 'interfaces/StoreLocalization';
import Store from 'interfaces/Store';
import { isNumber } from 'util';

const NAME_MIN_LENGTH = 3;
const NAME_MAX_LENGTH = 255;
const TAGLINE_MIN_LENGTH = 3;
const TAGLINE_MAX_LENGTH = 255;
const ABSTRACT_MIN_LENGTH = 3;
const ABSTRACT_MAX_LENGTH = 255;
const DESCRIPTION_MIN_LENGTH = 3;

interface StoreLocalizationValidationError {
  name?: string;
  tagline?: string;
  abstract?: string;
  description?: string;
}

interface StoreValidationErrors {
  affiliateProgramId?: string;
  greenKmReward?: string;
  localizations?: StoreLocalizationValidationError[];
  localizationsQty?: string;
  categories?: string;
  landscapeBanner?: string;
}

const validateLocalizationsQty = (store: Store): string | undefined => {
  if (
    (store?.localizations?.length ?? 0) === 0 ||
    (store?.localizations
      ?.map((content) => omit(content, ['languageCode']))
      .filter((content) => content.active)
      .filter((content) => Object.keys(content).length > 0).length ?? 0) === 0
  ) {
    return 'bo.stores.edit.fields.localizations.validation';
  }
};

const validateLandscapeBanner = (store: Store): string | undefined =>
  isEmpty(store.landscapeBanner) ? 'bo.stores.edit.fields.landscapeBanner.validation' : undefined;

const validateName = (localization: StoreLocalization, translate: Function): string | undefined => {
  if (isEmpty(localization.name))
    return translate('bo.stores.edit.fields.name.validation.required', {
      language: getLanguageName(localization?.languageCode ?? ''),
    });

  if ((localization?.name?.length ?? 0) < NAME_MIN_LENGTH)
    return translate('bo.stores.edit.fields.name.validation.minLength', {
      language: getLanguageName(localization?.languageCode ?? ''),
      minLength: NAME_MIN_LENGTH,
    });

  if ((localization?.name?.length ?? 0) > NAME_MAX_LENGTH)
    return translate('bo.stores.edit.fields.name.validation.maxLength', {
      language: getLanguageName(localization?.languageCode ?? ''),
      maxLength: NAME_MAX_LENGTH,
    });
};

const validateTagline = (localization: StoreLocalization, translate: Function): string | undefined => {
  if ((localization?.tagline?.length ?? 0) > TAGLINE_MAX_LENGTH)
    return translate('bo.stores.edit.fields.tagline.validation.maxLength', {
      language: getLanguageName(localization?.languageCode ?? ''),
      maxLength: TAGLINE_MAX_LENGTH,
    });
};

const validateAbstract = (localization: StoreLocalization, translate: Function): string | undefined => {
  if (isEmpty(localization.abstract))
    return translate('bo.stores.edit.fields.abstract.validation.required', {
      language: getLanguageName(localization?.languageCode ?? ''),
    });

  if ((localization?.abstract?.length ?? 0) < ABSTRACT_MIN_LENGTH)
    return translate('bo.stores.edit.fields.abstract.validation.minLength', {
      language: getLanguageName(localization?.languageCode ?? ''),
      minLength: ABSTRACT_MIN_LENGTH,
    });

  if ((localization?.abstract?.length ?? 0) > ABSTRACT_MAX_LENGTH)
    return translate('bo.stores.edit.fields.abstract.validation.maxLength', {
      language: getLanguageName(localization?.languageCode ?? ''),
      maxLength: ABSTRACT_MAX_LENGTH,
    });
};

const validateDescription = (localization: StoreLocalization, translate: Function): string | undefined => {
  if (isEmpty(localization.description))
    return translate('bo.stores.edit.fields.description.validation.required', {
      language: getLanguageName(localization?.languageCode ?? ''),
    });

  if ((localization?.description?.length ?? 0) < DESCRIPTION_MIN_LENGTH)
    return translate('bo.stores.edit.fields.description.validation.minLength', {
      language: getLanguageName(localization?.languageCode ?? ''),
      minLength: DESCRIPTION_MIN_LENGTH,
    });
};

const validateAffiliateProgramId = (store: Store): string | undefined =>
  !isNumber(store.affiliateProgramId) ? 'bo.stores.edit.fields.affiliateProgram.validation.required' : undefined;

const validateCategories = (store: Store): string | undefined =>
  isEmpty(store.categories) ? 'bo.stores.edit.fields.categories.validation.required' : undefined;

const validateGreenKmReward = (store: Store): string | undefined => {
  if (store.isGreenDelivery)
    return !isNumber(store.greenKmReward) || store.greenKmReward < 0
      ? 'bo.stores.edit.fields.greenKmReward.validation'
      : undefined;
  return undefined;
};

const validateLocalization = (
  localization: StoreLocalization,
  translate: Function,
): StoreLocalizationValidationError => ({
  name: localization.active ? validateName(localization, translate) : undefined,
  tagline: localization.active ? validateTagline(localization, translate) : undefined,
  abstract: localization.active ? validateAbstract(localization, translate) : undefined,
  description: localization.active ? validateDescription(localization, translate) : undefined,
});

const validateStore = (store: Store, translate: Function): StoreValidationErrors => ({
  affiliateProgramId: validateAffiliateProgramId(store),
  categories: validateCategories(store),
  greenKmReward: validateGreenKmReward(store),
  landscapeBanner: validateLandscapeBanner(store),
  localizations: compact(
    store.localizations?.map((localization: StoreLocalization) => validateLocalization(localization, translate)),
  ),
  localizationsQty: validateLocalizationsQty(store),
});

export const validationErrorMessages = (store: Store, translate: Function): string[] => {
  let errors = [
    validateAffiliateProgramId(store),
    validateCategories(store),
    validateGreenKmReward(store),
    validateLandscapeBanner(store),
    validateLocalizationsQty(store),
  ];

  each(compact(store.localizations), (localization: StoreLocalization) => {
    errors = errors.concat(Object.values(validateLocalization(localization, translate)));
  });

  return compact(flatten(errors));
};

export default validateStore;
