import React, { FC, useState, useEffect, useContext } from 'react';
import { useForm, useFormState, AnyObject } from 'react-final-form';
import { Confirm, useNotify, useRedirect, useUpdate } from 'react-admin';
import EditToolbar from 'components/EditToolbar';
import { SaveHandlerContext } from 'providers/saveHandlerProvider';

interface EditPostToolbarProps {
  resource?: string;
  prepareData?: Function;
  validationErrorMessages: Function;
}

const EditPostToolbar: FC<EditPostToolbarProps> = ({ resource, prepareData, validationErrorMessages, ...props }) => {
  const [specifyDateOpen, setSpecifyDateOpen] = useState(false);
  const [canStartUpdate, setCanStartUpdate] = useState(false);
  const formState: AnyObject = useFormState();
  const form: AnyObject = useForm();
  const notify = useNotify();
  const redirectTo = useRedirect();
  const { setAfterSave } = useContext(SaveHandlerContext);

  const [update] = useUpdate(
    resource,
    formState.values.id,
    prepareData ? prepareData(formState.values) : formState.values,
    {},
    {
      undoable: false,
      onSuccess: () => {
        notify(`bo.${resource}.feedback.updateCompleted`, 'info');
        redirectTo(`/${resource}`);
      },
      onFailure: (e: { message: string[] }) => {
        notify(typeof e.message === 'string' ? e.message : e.message[0], 'warning');
      },
    },
  );

  const checkPublicationInfos = (): void => {
    const { published, publishedAt } = formState.values;
    if (published && !publishedAt) {
      setSpecifyDateOpen(true);
      return;
    }
    setCanStartUpdate(true);
  };

  const onPublicationInfosConfirm = async (): Promise<void> => {
    await form.change('publishedAt', new Date(Date.now()).toISOString());
    setSpecifyDateOpen(false);
    setCanStartUpdate(true);
  };
  const onCloseInfo = (): void => {
    setAfterSave(false);
    setSpecifyDateOpen(false);
  };
  useEffect(() => {
    canStartUpdate && update();
  }, [canStartUpdate, form]);

  return (
    <>
      <EditToolbar
        onSave={checkPublicationInfos}
        resource={resource}
        prepareData={prepareData}
        validationErrorMessages={validationErrorMessages}
        {...props}
      />
      <Confirm
        isOpen={specifyDateOpen}
        title={`bo.${resource}.feedback.publishedWithoutData.title`}
        content={`bo.${resource}.feedback.publishedWithoutData.question`}
        confirm="Yes"
        confirmColor="primary"
        cancel="Cancel"
        onConfirm={onPublicationInfosConfirm}
        onClose={onCloseInfo}
      />
    </>
  );
};

export default EditPostToolbar;
