import React, { FC } from 'react';
import { DeleteButton, useTranslate } from 'react-admin';
import Category from 'interfaces/Category';

interface ICategoryDeleteButton {
  record?: Category;
  label?: string;
}

const CategoryDeleteButton: FC<ICategoryDeleteButton> = ({ record, label, ...props }) => {
  const t = useTranslate();
  const groupAssociations = [record?.storesQty, record?.promotionQty, record?.donationQty];

  return (
    <DeleteButton
      label={label}
      confirmTitle={`${t('bo.categories.delete')}  ${record?.localizations[0].name ?? ''}`}
      disabled={groupAssociations.some((item?: number) => item && item > 0)}
      undoable={false}
      record={record}
      {...props}
    />
  );
};
export default CategoryDeleteButton;
