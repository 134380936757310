import theme from 'theme';
const categories = [
  { id: 'sdg-1', color: theme.colors.categories.sdg1, name: 'No Poverty' },
  { id: 'sdg-2', color: theme.colors.categories.sdg2, name: 'Zero Hunger' },
  { id: 'sdg-3', color: theme.colors.categories.sdg3, name: 'Good Health and Well-being' },
  { id: 'sdg-4', color: theme.colors.categories.sdg4, name: 'Quality Education' },
  { id: 'sdg-5', color: theme.colors.categories.sdg5, name: 'Gender Equality' },
  { id: 'sdg-6', color: theme.colors.categories.sdg6, name: 'Clean Water and Sanitation' },
  { id: 'sdg-7', color: theme.colors.categories.sdg7, name: 'Affordable and Clean Energy' },
  { id: 'sdg-8', color: theme.colors.categories.sdg8, name: 'Decent Work and Economic Growth' },
  { id: 'sdg-9', color: theme.colors.categories.sdg9, name: 'Industry, Innovation, and Infrastructure' },
  { id: 'sdg-10', color: theme.colors.categories.sdg10, name: 'Reducing Inequality' },
  { id: 'sdg-11', color: theme.colors.categories.sdg11, name: 'Sustainable Cities and Communities' },
  { id: 'sdg-12', color: theme.colors.categories.sdg12, name: 'Responsible Consumption and Production' },
  { id: 'sdg-13', color: theme.colors.categories.sdg13, name: 'Climate Action' },
  { id: 'sdg-14', color: theme.colors.categories.sdg14, name: 'Life Below Water' },
  { id: 'sdg-15', color: theme.colors.categories.sdg15, name: 'Life On Land' },
  { id: 'sdg-16', color: theme.colors.categories.sdg16, name: 'Peace, Justice, and Strong Institutions' },
  { id: 'sdg-17', color: theme.colors.categories.sdg17, name: 'Partnerships for the Goals' },
];
export default categories;
