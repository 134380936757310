import languages from 'utils/languages';
import Post from 'interfaces/Post';
import Store from 'interfaces/Store';
import Promos from 'interfaces/Promos';
import Donations from 'interfaces/Donations';
import Category from 'interfaces/Category';
import resources from 'shared/resources';
import HttpClient from 'providers/httpClient';
import some from 'lodash/some';
import IDonationsSubscriptions from 'interfaces/DonationsSubscriptions';
import IUserWallet from 'interfaces/UserWallet';

const UPDATE_SORTING = 'update-sorting';
const UPDATE_DONATION_SUB = 'donations-subscriptions-update';
const GET_DONATION_SUB = 'get-filtered-subs';

const GET_USER_WALLET = 'get-user-wallet';
const GET_DONATION = 'get-donation';

const UPDATE_SORTING_CAT = 'rearrange';
/**
 * Since we get image in format [name].[extension] from query response
 * but we need a full path for show images already uploaded in all edit mode
 * This function overwrite portraitBannerId or landscapeBannerId response keys with their full path
 * @param item query response
 */
const addPortraitImagePath = (item: Promos | Post): object => {
  if (item.portraitBannerId)
    return {
      path: `${process.env.REACT_APP_ASSET_STORE}/original/${item.portraitBannerId}`,
    };
  return {};
};
const addLandscapeImagePath = (item: Promos | Store | Post): object => {
  if (item.landscapeBannerId)
    return {
      path: `${process.env.REACT_APP_ASSET_STORE}/original/${item.landscapeBannerId}`,
    };

  return {};
};

/**
 * Add key "active: true" on language that are activated by admin
 * @param item response data
 * @param key Since we have different key names in response for localization, we need to pass the correct name to iterate
 */

const addActiveLanguages = (response: any, key: string) => {
  return languages.reduce((acc: any, item) => {
    const isActivated = some(response[key], ['languageCode', item.id]);
    if (isActivated) {
      const findLangActive = response[key].find((el: any) => el.languageCode === item.id);
      acc = [...acc, { ...findLangActive, active: true }];
    } else {
      acc = [...acc, { active: false, languageCode: item.id }];
    }
    return acc;
  }, []);
};
const addPostPublished = (item: Post): object => {
  item.published = !!item.publishedAt;
  return item;
};

const addPostData = (data: any): object => {
  data = addPostPublished(data);
  const portraitBanner = addPortraitImagePath(data);
  const landscapeBanner = addLandscapeImagePath(data);
  const postContents = addActiveLanguages(data, 'postContents');
  return {
    ...data,
    portraitBanner,
    landscapeBanner,
    postContents,
  };
};

const addCategoryData = (data: any): object => {
  const portraitBanner = addPortraitImagePath(data);
  const landscapeBanner = addLandscapeImagePath(data);
  return {
    ...data,
    portraitBanner,
    landscapeBanner,
  };
};

const addStoreData = (data: any): object => {
  const landscapeBanner = addLandscapeImagePath(data);
  const localizations = addActiveLanguages(data, 'localizations');
  // const localizations = addActiveLanguages1(data);
  return {
    ...data,
    landscapeBanner,
    localizations,
  };
};

const addPromoData = (data: any): object => {
  const portraitBanner = addPortraitImagePath(data);
  const landscapeBanner = addLandscapeImagePath(data);
  const locales = addActiveLanguages(data, 'locales');
  return {
    ...data,
    portraitBanner,
    landscapeBanner,
    locales,
  };
};

const updateSorting = async (resource: string, params: any): Promise<object | undefined> => {
  try {
    const response: object & { json?: object } = await HttpClient(
      `${process.env.REACT_APP_BASE_API_URL}/${resource}/update-sorting`,
      {
        method: 'PUT',
        body: JSON.stringify(params),
      },
    );
    return response.json;
  } catch (error) {
    console.log(error);
  }
};
const updateSortingCategories = async (resource: string, params: any): Promise<object | undefined> => {
  try {
    const response: object & { json?: object } = await HttpClient(
      `${process.env.REACT_APP_BASE_API_URL}/${resource}/rearrange`,
      {
        method: 'PATCH',
        body: JSON.stringify(params),
      },
    );
    return response.json;
  } catch (error) {
    console.log(error);
  }
};
const updateDonationSub = async (resource: string, params: any, id: string): Promise<object | undefined> => {
  try {
    const response: object & { json?: object } = await HttpClient(
      `${process.env.REACT_APP_BASE_API_URL}/${resource}/${id}/transaction_state`,
      {
        method: 'PATCH',
        body: JSON.stringify(params),
      },
    );
    return response.json;
  } catch (error) {
    console.log(error);
  }
};
interface IGetUserWallet {
  json?: IUserWallet;
}
const getUserWallet = async (resource: string, params: { id: string }): Promise<object | undefined> => {
  try {
    const response: IGetUserWallet = await HttpClient(
      `${process.env.REACT_APP_BASE_API_URL}/${resource}/?filter=userId||eq||${params.id}`,
      {
        method: 'GET',
      },
    );
    return response?.json;
  } catch (error) {
    console.log(error);
  }
};

interface IGetDonation {
  json?: Donations;
}
const getDonation = async (resource: string, params: { id: string }): Promise<object | undefined> => {
  try {
    const response: IGetUserWallet = await HttpClient(
      `${process.env.REACT_APP_BASE_API_URL}/${resource}/${params.id}`,
      {
        method: 'GET',
      },
    );
    return response?.json;
  } catch (error) {
    console.log(error);
  }
};

interface IGetDonationSunsResponse {
  json?: IDonationsSubscriptions;
}
const getDonationSub = async (resource: string, payload: string): Promise<object | undefined> => {
  try {
    const response: IGetDonationSunsResponse = await HttpClient(
      `${process.env.REACT_APP_BASE_API_URL}/${resource}/${payload}`,
      {
        method: 'GET',
      },
    );
    return response?.json;
  } catch (error) {
    console.log(error);
  }
};

export default (dataProvider: Function): object => async (
  type: string,
  resource: string,
  params: any,
): Promise<object | undefined> => {
  if (type === UPDATE_SORTING) {
    updateSorting(resources[resource], params);
    return { data: true };
  }
  if (type === UPDATE_SORTING_CAT) {
    updateSortingCategories(resources[resource], params);
    return { data: true };
  }
  if (type === UPDATE_DONATION_SUB) {
    updateDonationSub(resources[resource], params, params.id);
    return { data: true };
  }
  if (type === GET_DONATION_SUB) {
    const resJson = await getDonationSub(resources[resource], params.id);
    return { data: resJson };
  }
  if (type === GET_USER_WALLET) {
    const resJson = await getUserWallet(resources[resource], params);
    return { data: resJson };
  }
  if (type === GET_DONATION) {
    const resJson = await getDonation(resources[resource], params);
    return { data: resJson };
  }

  const response = await dataProvider(type, resources[resource], params);
  const { data, ...rest } = response;
  let modifiedResponse;

  switch (resource) {
    case 'posts':
      if (type === 'GET_ONE') {
        modifiedResponse = { data: addPostData(response.data) };
      }
      if (type === 'GET_LIST') {
        modifiedResponse = { ...rest, data: data.map((item: Post) => addPostData(item)) };
      }
      break;
    case 'categories':
      if (type === 'GET_ONE') {
        modifiedResponse = { data: addCategoryData(data) };
      }
      if (type === 'GET_LIST') {
        modifiedResponse = { ...rest, data: data.map((item: Category) => addCategoryData(item)) };
      }
      break;
    case 'stores':
      if (type === 'GET_ONE') {
        modifiedResponse = { data: addStoreData(data) };
      }
      if (type === 'GET_LIST') {
        modifiedResponse = { ...rest, data: data.map((item: Store) => addStoreData(item)) };
      }
      break;
    case 'promos':
      if (type === 'GET_ONE') {
        modifiedResponse = { data: addPromoData(data) };
      }
      if (type === 'GET_LIST') {
        modifiedResponse = { ...rest, data: data.map((item: Promos) => addPromoData(item)) };
      }
      break;
    case 'donations':
      if (type === 'GET_ONE') {
        modifiedResponse = { data: addPromoData(data) };
      }
      if (type === 'GET_LIST') {
        modifiedResponse = { ...rest, data: data.map((item: Donations) => addPromoData(item)) };
      }
      break;
    case 'subscriptions':
      if (type === 'GET_ONE') {
        modifiedResponse = { data: data.data };
      }
      break;
  }

  return modifiedResponse || response;
};
