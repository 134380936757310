import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  codeList: {
    border: '1px solid gray',
    borderRadius: '8px',
    height: '80%',
    overflow: 'scroll',
    width: '800px',
    minWidth: '340px',
    background: '#fff',
  },
});

export default useStyles;
