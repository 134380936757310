import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  loaderWrapper: {
    position: 'absolute',
    height: '100%',
    minWidth: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 100,
    backgroundColor: 'rgba(255, 255, 255, 50%)',
  },
});

export default useStyles;
