import { compact, flatten, each, isEmpty, omit, isNumber } from 'lodash';
import { getLanguageName } from 'utils/languages';
import PromosLocalization from 'interfaces/PromosLocalization';
import Promos from 'interfaces/Promos';

const NAME_MIN_LENGTH = 3;
const NAME_MAX_LENGTH = 255;
const DESCRIPTION_MIN_LENGTH = 3;

interface PromosLocalizationValidationError {
  name?: string;
  abstract?: string;
  description?: string;
  details?: string;
  instructions?: string;
}

interface PromosValidationErrors {
  locales?: PromosLocalizationValidationError[];
  localizationsQty?: string;
  partnerName?: string;
  promoCode?: string;
  promoCodeType?: string;
  price?: string;
  landscapeBanner?: string;
  portraitBanner?: string;
  categories: string | undefined;
}

const validateLocalizationsQty = (promos: Promos): string | undefined => {
  if (
    (promos?.locales?.length ?? 0) === 0 ||
    (promos?.locales
      ?.map((content) => omit(content, ['languageCode']))
      .filter((content) => content.active)
      .filter((content) => Object.keys(content).length > 0).length ?? 0) === 0
  ) {
    return 'bo.stores.edit.fields.localizations.validation';
  }
};

const validateLandscapeBanner = (promos: Promos): string | undefined =>
  isEmpty(promos.landscapeBanner) ? 'bo.promos.edit.fields.landscapeBanner.validation' : undefined;

const validatePortraitBanner = (promos: Promos): string | undefined =>
  isEmpty(promos.portraitBanner) ? 'bo.promos.edit.fields.portraitBanner.validation' : undefined;

const validatePromoCode = (promos: Promos, hasCodes: number): string | undefined =>
  promos.public && isEmpty(promos.promoCode) && !hasCodes ? 'bo.promos.edit.fields.promoCode.required' : undefined;

const validatePromoCodeType = (promos: Promos): string | undefined =>
  isEmpty(promos.promoCodeType) ? 'bo.promos.edit.fields.promoType.required' : undefined;

const validateGreenKm = (promos: Promos): string | undefined =>
  !isNumber(promos.price) || promos.price < 0 ? 'bo.promos.edit.fields.greenKmPrice.validation' : undefined;

const validatePartnerName = (promos: Promos): string | undefined =>
  isEmpty(promos.partnerName) ? 'bo.promos.edit.fields.partnerName.required' : undefined;

const validateCategories = (promos: Promos): string | undefined =>
  isEmpty(promos.categories) ? 'bo.promos.edit.fields.categories.validation.required' : undefined;

const validateName = (localization: PromosLocalization, translate: Function): string | undefined => {
  if (isEmpty(localization.name))
    return translate('bo.stores.edit.fields.name.validation.required', {
      language: getLanguageName(localization?.languageCode ?? ''),
    });

  if ((localization?.name?.length ?? 0) < NAME_MIN_LENGTH)
    return translate('bo.stores.edit.fields.name.validation.minLength', {
      language: getLanguageName(localization?.languageCode ?? ''),
      minLength: NAME_MIN_LENGTH,
    });

  if ((localization?.name?.length ?? 0) > NAME_MAX_LENGTH)
    return translate('bo.stores.edit.fields.name.validation.maxLength', {
      language: getLanguageName(localization?.languageCode ?? ''),
      maxLength: NAME_MAX_LENGTH,
    });
};
const validateDescription = (localization: PromosLocalization, translate: Function): string | undefined => {
  if (isEmpty(localization.description))
    return translate('bo.stores.edit.fields.description.validation.required', {
      language: getLanguageName(localization?.languageCode ?? ''),
    });

  if ((localization?.description?.length ?? 0) < DESCRIPTION_MIN_LENGTH)
    return translate('bo.stores.edit.fields.description.validation.minLength', {
      language: getLanguageName(localization?.languageCode ?? ''),
      minLength: DESCRIPTION_MIN_LENGTH,
    });
};

const validateLocalization = (
  localization: PromosLocalization,
  translate: Function,
): PromosLocalizationValidationError => ({
  name: localization.active ? validateName(localization, translate) : undefined,
  description: localization.active ? validateDescription(localization, translate) : undefined,
});

const validatePromos = (promos: Promos, translate: Function, hasCodes: number): PromosValidationErrors => ({
  landscapeBanner: validateLandscapeBanner(promos),
  portraitBanner: validatePortraitBanner(promos),
  promoCode: validatePromoCode(promos, hasCodes),
  promoCodeType: validatePromoCodeType(promos),
  price: validateGreenKm(promos),
  partnerName: validatePartnerName(promos),
  categories: validateCategories(promos),
  locales: compact(
    promos.locales?.map((localization: PromosLocalization) => validateLocalization(localization, translate)),
  ),
  localizationsQty: validateLocalizationsQty(promos),
});

export const validationErrorMessages = (promos: Promos, hasCodes: number, translate: Function): string[] => {
  let errors = [
    validateLandscapeBanner(promos),
    validateLocalizationsQty(promos),
    validatePromoCode(promos, hasCodes),
    validatePromoCodeType(promos),
    validateGreenKm(promos),
    validatePartnerName(promos),
  ];

  each(compact(promos.locales), (localization: PromosLocalization) => {
    errors = errors.concat(Object.values(validateLocalization(localization, translate)));
  });

  return compact(flatten(errors));
};

export default validatePromos;
