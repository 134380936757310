import * as React from 'react';
import { Route } from 'react-router-dom';
import StoreSort from 'pages/Stores/StoreSort';
import DonationsTransactionShow from './pages/DonationsTransaction/DonationsTransactionShow';
import CategorySort from 'pages/Categories/CategorySort';

export default [
  <Route key="sort-highlighted" exact path="/stores/sort-highlighted" component={StoreSort} />,
  <Route key="sort-categories" exact path="/categories/sort-categories" component={CategorySort} />,
  <Route key="donations-subscriptions" path="/donations-subscriptions/:id" component={DonationsTransactionShow} />,
];
