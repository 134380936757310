import React, { FC, useState } from 'react';
import { TextInput, useTranslate } from 'react-admin';
import Dropzone from 'react-dropzone';
import { Button, Modal } from '@material-ui/core';

import PromoCodesList from 'components/PromoCodesList';
import HttpClient from 'providers/httpClient';
import useStyles from './styles';

interface ICodeCSV {
  value?: string;
  totalSubscriptions?: boolean;
}
interface IPromosCodeEdit {
  promoId?: string;
  typeSelected?: string;
  promoCodeType?: string;
  isPublic?: boolean;
  CSVCodes?:
    | {
        value?: string;
        totalSubscriptions?: boolean;
      }
    | any;
}

const PromosCodeEdit: FC<IPromosCodeEdit> = ({ promoId, promoCodeType, CSVCodes, isPublic }) => {
  const classes = useStyles();
  const t = useTranslate();
  const [open, setOpen] = useState(false);
  const [fileName, setFileName] = useState<string>();
  const hasCodes = CSVCodes?.length;

  const handleToggleModal = (): void => {
    setOpen(!open);
  };

  const getUploadUrl = async () => {
    try {
      const response: object & {
        json?: {
          uploadUrl: string;
        };
      } = await HttpClient(`${process.env.REACT_APP_BASE_API_URL}/promos/admin/promos/${promoId}/import_jobs`, {
        method: 'POST',
      });
      return response;
    } catch (error) {
      console.log(error);
    }
  };

  const handleDrop = async (file: any): Promise<void> => {
    const getUrlToUploadCsv = await getUploadUrl();
    const uploadUrl = getUrlToUploadCsv?.json?.uploadUrl;

    if (file && uploadUrl) {
      const input = file[0];
      setFileName(input.name);
      const response = await fetch(uploadUrl, {
        method: 'PUT',
        headers: {
          'Content-Type': 'text/csv',
        },
        body: input,
      });
    }
  };
  const ShowCodesDisabled = Boolean(!hasCodes || fileName);

  const CodeDropzone: FC = () => (
    <>
      <p>{t(`bo.promos.edit.fields.promoType.${promoCodeType}`)} </p>

      <Dropzone onDrop={handleDrop} multiple={false} accept="text/csv">
        {({ getRootProps, getInputProps }) => (
          <div {...getRootProps()} className="dropzone">
            <input {...getInputProps()} />
            <p>Drag and drop some files here, or click to select files</p>
          </div>
        )}
      </Dropzone>

      {fileName && <p>{fileName}</p>}
    </>
  );

  return (
    <>
      {promoCodeType === 'codes' ? (
        <>
          {!isPublic && <CodeDropzone />}
          <Button variant="contained" color="primary" disabled={ShowCodesDisabled} onClick={handleToggleModal}>
            {t('bo.promos.edit.fields.promoCode.showCodes')}
          </Button>
          <Modal
            open={open}
            onClose={handleToggleModal}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            className={classes.modal}
          >
            <PromoCodesList codes={CSVCodes} />
          </Modal>
        </>
      ) : (
        <>
          {!isPublic && <div>{t(`bo.promos.edit.fields.promoType.${promoCodeType}`)} </div>}
          <TextInput label="bo.promos.edit.fields.promoCode.label" source="promoCode" fullWidth disabled={isPublic} />
        </>
      )}
    </>
  );
};
export default PromosCodeEdit;
