import React, { FC } from 'react';
import { RadioButtonGroupInput, TextInput, useTranslate } from 'react-admin';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';

import useStyles from './styles';

interface IPromosCodeCreate {
  typeSelected?: string;
}

const PromosCodeCreate: FC<IPromosCodeCreate> = ({ typeSelected }) => {
  const classes = useStyles();
  const t = useTranslate();

  return (
    <>
      <RadioButtonGroupInput
        source="promoCodeType"
        label="bo.promos.edit.fields.promoType.label"
        choices={[
          { id: 'link', name: 'bo.promos.edit.fields.promoType.typeLink' },
          { id: 'code', name: 'bo.promos.edit.fields.promoType.typeCode' },
          { id: 'codes', name: 'bo.promos.edit.fields.promoType.typeCodes' },
        ]}
      />
      {typeSelected === 'codes' && (
        <Paper className={classes.promosHidden} elevation={3}>
          <InfoOutlinedIcon />
          <Typography className={classes.textInfo}>{t('bo.promos.edit.fields.promoCode.info')}</Typography>
        </Paper>
      )}
      {(typeSelected === 'link' || typeSelected === 'code') && (
        <TextInput label="bo.promos.edit.fields.promoCode.label" source="promoCode" fullWidth />
      )}
    </>
  );
};
export default PromosCodeCreate;
