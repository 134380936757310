import React, { FC } from 'react';
import { Create, SimpleForm, TextInput, PasswordInput, required, email } from 'react-admin';
import SectionTitle from 'components/SectionTitle';

const AdminUserCreate: FC = (props) => {
  const samePassword = () => (value: string, allValues: any) =>
    value !== allValues.password ? 'bo.admin_user.create.fields.password.validate' : undefined;

  return (
    <Create title="bo.admin_user.create.title" {...props}>
      <SimpleForm {...props}>
        <SectionTitle label="bo.admin_user.create.title" />
        <TextInput source="firstName" validate={required()} />
        <TextInput source="lastName" validate={required()} />
        <TextInput source="email" type="email" validate={[required(), email()]} />
        <PasswordInput source="password" validate={[required()]} />
        <PasswordInput source="repeatPassword" validate={[required(), samePassword()]} />
      </SimpleForm>
    </Create>
  );
};

export default AdminUserCreate;
