import React, { FC } from 'react';
import Box from '@material-ui/core/Box';
import { SelectArrayInput, TextInput } from 'react-admin';
import { useForm } from 'react-final-form';
import ImageInputUploader from 'components/ImageInputUploader';
import countries from 'utils/countries';
import useStyles from './styles';

const CategoryMainContent: FC = () => {
  const classes = useStyles();
  const form = useForm();

  const onImageChange = (id: string, orientation: string): void => {
    form.change(`${orientation}BannerId`, id);
  };

  return (
    <>
      <Box display="flex" flexDirection="column" justifyContent="flex-start" alignContent="flex-start">
        <TextInput key="portraitBannerId" source="portraitBannerId" className={classes.hidden} />
        <TextInput key="landscapeBannerId" source="landscapeBannerId" className={classes.hidden} />
        <SelectArrayInput source="countries" label="bo.stores.edit.fields.countries.label" choices={countries} />
        <Box display="flex" flexDirection="row" justifyContent="space-between">
          <Box width="48%">
            <ImageInputUploader
              source="portraitBanner"
              label="bo.categories.edit.fields.portraitBanner.label"
              orientation="portrait"
              onChange={onImageChange}
            />
          </Box>
          <Box width="48%">
            <ImageInputUploader
              source="landscapeBanner"
              label="bo.categories.edit.fields.landscapeBanner.label"
              orientation="landscape"
              onChange={onImageChange}
            />
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default CategoryMainContent;
