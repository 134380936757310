import React, { FC, useState } from 'react';
import { useQuery, useTranslate, Loading } from 'react-admin';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Paper from '@material-ui/core/Paper';

import map from 'lodash/map';

import SubTab from './DonationSubsTable';

interface IDonationsTransactionShow {
  match: {
    params: {
      id: string;
    };
  };
}
const DonationsTransactionShow: FC<IDonationsTransactionShow> = ({ match }) => {
  const [value, setValue] = useState('AUTHORIZED');
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const t = useTranslate();

  const tabs = [
    { id: 'AUTHORIZED', name: t(`bo.donation_sub.tabs.authorized`) },
    // { id: 'PENDING', name: t(`bo.donation_sub.tabs.pending`) },
    { id: 'CONFIRMED', name: t(`bo.donation_sub.tabs.approve`) },
    { id: 'VOID', name: t(`bo.donation_sub.tabs.reject`) },
  ];

  const { data, loading } = useQuery({
    type: 'get-filtered-subs',
    resource: 'donations-subscriptions',
    payload: { id: `${match.params.id}?transactionState=${value}&limit=${rowsPerPage}&page=${page + 1}` },
  });

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangePage = (_event: unknown, newPage: number): void => {
    setPage(newPage);
  };

  const handleChangeTab = (_event: React.ChangeEvent<{}>, value: string): void => {
    setValue(value);
    setPage(0);
  };

  return (
    (!loading && (
      <>
        <Paper square>
          <Tabs
            value={value}
            onChange={handleChangeTab}
            indicatorColor="primary"
            textColor="primary"
            variant="fullWidth"
            aria-label="full width tabs example"
          >
            {map(tabs, (choice) => (
              <Tab key={choice.id} label={choice.name} value={choice.id} />
            ))}
          </Tabs>
        </Paper>
        {value === 'AUTHORIZED' && (
          <SubTab
            response={data}
            hasAction={true}
            rowsPerPage={rowsPerPage}
            page={page}
            onSelectRowsPerPage={handleChangeRowsPerPage}
            onSelectPage={handleChangePage}
          />
        )}
        {/* {value === 'PENDING' && (
          <SubTab
            response={data}
            hasAction={true}
            rowsPerPage={rowsPerPage}
            page={page}
            onSelectRowsPerPage={handleChangeRowsPerPage}
            onSelectPage={handleChangePage}
          />
        )} */}
        {value === 'CONFIRMED' && (
          <SubTab
            response={data}
            rowsPerPage={rowsPerPage}
            page={page}
            onSelectRowsPerPage={handleChangeRowsPerPage}
            onSelectPage={handleChangePage}
          />
        )}
        {value === 'VOID' && (
          <SubTab
            response={data}
            rowsPerPage={rowsPerPage}
            page={page}
            onSelectRowsPerPage={handleChangeRowsPerPage}
            onSelectPage={handleChangePage}
          />
        )}
      </>
    )) || <Loading />
  );
};
export default DonationsTransactionShow;
