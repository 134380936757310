import React, { FC, useState, useCallback } from 'react';
import DropzoneProps from 'interfaces/Dropzone';
import Box from '@material-ui/core/Box';
import useStyles from './styles';

import { useDropzone } from 'react-dropzone';

interface ImageInputUploaderProps {
  isUploading: boolean;
  handleImageChange: Function;
}

const ImageInputUploader: FC<ImageInputUploaderProps> = ({ isUploading, handleImageChange }) => {
  const classes = useStyles();
  const [image, setImage] = useState<string | undefined>(undefined);

  const onDrop = useCallback((acceptedFiles) => {
    // this callback will be called after files get dropped, we will get the acceptedFiles. If you want, you can even access the rejected files too
    acceptedFiles.map((file: Blob) => {
      // Initialize FileReader browser API
      const reader = new FileReader();
      // onload callback gets called after the reader reads the file data
      reader.onload = function (e) {
        // add the image into the state. Since FileReader reading process is asynchronous, its better to get the latest snapshot state (i.e., prevState) and update it.
        const image = e?.target?.result || '';
        setImage(image as string);
      };
      // Read the file as Data URL (since we accept only images)
      reader.readAsDataURL(file);
      return file;
    });
    handleImageChange(acceptedFiles[0]);
  }, []);

  return (
    <Box className={classes.dropzone}>
      <Dropzone onDrop={onDrop} accept={'image/*'} multiple={false} />

      {image && (
        <Box width="200px" style={{ opacity: isUploading ? 0.5 : 1 }}>
          <img style={{ maxWidth: '100%' }} src={image} />
        </Box>
      )}
    </Box>
  );
};

export const Dropzone: FC<DropzoneProps> = ({ onDrop, accept, multiple }) => {
  const classes = useStyles();

  // Initializing useDropzone hooks with options
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    multiple,
    accept,
  });

  /* 
    useDropzone hooks exposes two functions called getRootProps and getInputProps
    and also exposes isDragActive boolean
  */

  return (
    <div {...getRootProps()}>
      <input {...getInputProps()} />
      <div className="text-center">
        {isDragActive ? (
          <p className={classes.dropzoneContent}>Release to drop the file here</p>
        ) : (
          <p className={classes.dropzoneContent}>Drop a picture to upload, or click to select it.</p>
        )}
      </div>
    </div>
  );
};

export default ImageInputUploader;
